import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import colors from '../../../../styles/global.scss'
import VideoList from '../VideosList'
import { Container, Grid } from 'semantic-ui-react'
import { AddVideoCard } from '../../../CustomComponents/VideoCard'
import CreateVideoCard from '../../../CustomComponents/CreateVideoCard'
import { AudioSegment, VideoExtractor } from '../../../AudioSegmentation'
import { v1 as uuidv1 } from 'uuid'
import { isAdminSelector } from '../../../../recoil/selectors'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Checkbox } from 'semantic-ui-react'
import Loader from '../../../CustomComponents/Loader/Loader'
import ErrorCard from '../../../CustomComponents/ErrorCard'
import { IsShowError } from "../../../../recoil/atoms"

const VideoContainer = styled.div`
    {
        width: 100%;
        padding-left: 6rem;
    }
`

const FilterTagsWrapper = styled.div`
    {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
    }
`

const CheckBoxWrapper = styled.div`
        padding-right: 4rem;
        font-family: "Prompt-medium";
        display: flex;
`

const Label = styled.div`
        color: ${colors.white};
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-family: "Prompt-medium";
        display: flex;
`



const VideosWrapper = ({ children }) => (
    <VideoContainer >
        {children}
    </VideoContainer>
)


const Videos = (props) => {
    const [videos, setVideos] = useState([])
    const [videosFilter, setVideosFilter] = useState([])
    const [open, setOpen] = useState(false)
    //const [openError,setError] = useState(false)
    const [activeLoader, setActiveLoader] = useState(true)
    const [togglePublishVideo, setTogglePublishVideo] = useState(true)
    const [toggleUnPublishVideo, setToggleUnPublishVideo] = useState(true)
    const [toggleApproveVideo, setToggleApproveVideo] = useState(true)
    const isAdmin = useRecoilValue(isAdminSelector)
    const [openError, setError] = useRecoilState(IsShowError)
    const [errorDetail, setErrorDetailText] = useState("");

    useEffect(() => {
        if (props.firebase != null) {
            const fetchData = async () => {
                setActiveLoader(true)
                await getVideosList()
                setActiveLoader(false)
            }
            fetchData()
        }

    }, [props.firebase])


    useEffect(() => {
        let videosFilter = videos

        videosFilter = videosFilter.filter((video) => {
            if (toggleUnPublishVideo && togglePublishVideo && toggleApproveVideo)
                return video
            else if (toggleUnPublishVideo && togglePublishVideo)
                if (toggleApproveVideo)
                    return (video['published'] || !video['published']) || video['approved'] && video
                else {
                    return (video['published'] || !video['published']) && !video['approved'] && video
                }
            else if (togglePublishVideo && toggleApproveVideo)
                if (toggleApproveVideo)
                    return (video['published'] || video['approved']) && video
                else
                    return (video['published'] || !video['approved']) && video
            else if (toggleUnPublishVideo)
                return !video['published'] && video
            else if (togglePublishVideo)
                return video['published'] && video
            else if (toggleApproveVideo)
                return video['approved'] && video

        })
        setVideosFilter(videosFilter)

    }, [togglePublishVideo, toggleUnPublishVideo, toggleApproveVideo])


    const onCreateVideo = async (video) => {
        setActiveLoader(true)

        // or set atom here
        let videoExtractor = new VideoExtractor()
        let audioSegment = new AudioSegment()
        try {
            // let [outputAudioBuffer, duration] = await videoExtractor.extractAudio(video)
            // await audioSegment.loadModel()
            // let [segments, noHumanVoiceMoments] = await audioSegment.predict(outputAudioBuffer,duration)

            let [streamReader] = await videoExtractor.extractAudio(video)
            await audioSegment.loadModel()
            let [segments, noHumanVoiceMoments, duration] = await audioSegment.predictFromReaderStream(streamReader, duration)

            video['duration'] = duration

            noHumanVoiceMoments = noHumanVoiceMoments.map((moment, index) => {
                return {
                    id: uuidv1(),
                    text: "",
                    startTime: moment[0].time,
                    endTime: moment[1].time,
                    approved: false,
                    createdDate: Date.now(),
                    subtitleCount: 0
                }
            })

            await props.firebase.createVideoAndSubtitles(video, noHumanVoiceMoments);

            audioSegment.releaseModel()
            setOpen(false)
            setActiveLoader(false)
        } catch (error) {
            console.log(error.message);
            setErrorDetailText(error.message);
            setActiveLoader(false)
            setError(true);
        }

    }


    const getVideosList = async () => {
        return new Promise( async (resolve) => {
            let query = await props.firebase.onSnapshotVideosList()
            query.orderBy('createdDate', 'desc')
                .onSnapshot(async (snapshots) => {
                    const videos = snapshots.docs
                    let videoArr = []

                    for (const video of videos) {
                        let videoTemp = video.data()
                        videoTemp['id'] = video.id
                        await video.ref.collection('subtitles').get().then((subtitles) => {
                            videoTemp['subtitlesLength'] = subtitles.size
                            videoArr.push(videoTemp)
                        })
                    }
                    setVideos(videoArr)
                    setVideosFilter(videoArr)
                    resolve()
                })
        })

    }

    return (
        <>
            <VideosWrapper>
                <FilterTagsWrapper>
                    <CheckBoxWrapper >
                        <Checkbox

                            checked={toggleUnPublishVideo}
                            onChange={(event, data) => { setToggleUnPublishVideo(data.checked) }}
                        />
                        <Label>ยังไม่เผยแผร่</Label>
                    </CheckBoxWrapper>
                    <CheckBoxWrapper >
                        <Checkbox
                            checked={togglePublishVideo}
                            onChange={(event, data) => { setTogglePublishVideo(data.checked) }} />
                        <Label>กำลังเผยแพร่</Label>
                    </CheckBoxWrapper>
                    <CheckBoxWrapper >
                        <Checkbox
                            checked={toggleApproveVideo}
                            onChange={(event, data) => { setToggleApproveVideo(data.checked) }} />
                        <Label>ตรวจสอบแล้ว</Label>
                    </CheckBoxWrapper>
                </FilterTagsWrapper>
                <Container 
                fluid
                style={{
                    overflowX: `hidden`,
                    overflowY: `auto`,
                    height: `84vh`,
                    width: `100%`
                }} >
                    <Grid
                        columns={4}
                        doubling
                        style={{ margin: 0 }}
                    >
                        <Grid.Row style={{ padding: 0 }}>
                            {isAdmin && <CreateVideoCard
                                createMode={true}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                onCreateVideo={(video) => { onCreateVideo(video) }}
                                open={open}
                                trigger={
                                    <div style={{ padding: 0, height: 'fit-content' }}>
                                        <AddVideoCard />
                                    </div>
                                }
                            />}

                            <VideoList
                                videos={videosFilter}
                                onClickVideoListener={props.onClickVideoListener}
                                menuitems={props.menuitems}
                            />
                        </Grid.Row>
                    </Grid>
                </Container>


            </VideosWrapper>
            { activeLoader && <Loader />}
            {isAdmin &&
                <ErrorCard
                    onClose={() => { setError(false); setActiveLoader(false); setOpen(false) }}
                    onOpen={() => { setError(true); setActiveLoader(false) }}
                    size={'mini'}
                    open={openError}
                    errorText={errorDetail}
                />}
        </>
    )
}

export default Videos 