import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import colors from '../../../styles/global.scss'

const ConfirmModal = styled(Modal)`
    &.ui.modal {
        overflow: hidden;
        border-width: 2px;
        border-style: solid;
        border-color: ${colors.lightGrey};
        border-radius: 20px;
        background-color: ${colors.background};
        font-family: "Prompt-Medium";
        padding: 25px 20px 24px 36px;

        .header {
            background-color: ${colors.background};
            color: ${colors.white};
            font-size: 20px;
            padding: 0;
            font-family: "Prompt-Medium";
        }

        .content {
            background-color: ${colors.background};
            color: ${colors.white};
            padding: 0;
        }

        .actions {
            background-color: ${colors.background};
            padding: 0;
            
            .ui.button {
                width: 104px;
                height: 36px;
                font-size: 14px;
                padding: 0;
            }
        }
    }
`

export default ConfirmModal;