import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import colors from "../../../styles/global.scss"
import { Popup } from "semantic-ui-react"
import Icon from "../Icon"
import MenuItem from "../MenuItem"
import { motion } from "framer-motion"
import { useState } from "react"

const PopupMenuStyled = styled(Popup).attrs(props => ({
    // ${``}
}))`
    &.ui.popup {
        min-width: 144px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.lightGrey};
        border-radius: 8px;
    }

    &.ui.popup:before {
        display: none;
    }
`

const MenuItemWrapper = styled.div.attrs(props => ({
    // style: {
    //     color: props.disabled ? colors.lightGrey : props.color,
    // },
}))`
    color: ${props => props.disabled ? `${colors.lightGrey}` : `${props.color}`};
     {
        :hover {
            background-color: ${props =>
        props.disabled
            ? `${colors.background}`
            : `${colors.lightGrey}`};
            color: ${props =>
        props.disabled ? `${colors.lightGrey}` : `${props.hoverColor}`};
            cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
        }
    }
`

const ButtonStyle = {
    width: "32px",
    height: "32px",
    maxWidth: "32px",
    maxHeight: "32px",
    backgroundColor: `${colors.card}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const MenuButton = () => (
    <motion.div
        whileHover={{ backgroundColor: `${colors.card}` }}
        whileTap={{ backgroundColor: `${colors.lightGrey}` }}
        style={ButtonStyle}
    >
        <Icon color="white" name="ellipsisVertical" />
    </motion.div>
)

const PopupMenu = props => {
    const [open, setOpen] = useState(false)
    const { data } = props

    return (
        <PopupMenuStyled
            trigger={
                <div>
                    <MenuButton />
                </div>
            }
            on="click"
            inverted
            open={open}
            position="bottom left"
            hideOnScroll={true}
            onOpen={e => {
                setOpen(true)
                e.stopPropagation()
            }}
            onClose={e => {
                setOpen(false)
                e.stopPropagation()
            }}
        >
            {
                props.menuitems && props.menuitems.map((menu, index) => {
                    if (menu.type != undefined) {
                        if (menu.type === 'approved') {
                            menu.disabled = data['subtitle']['approved']
                        } else if (menu.type === 'not_approved') {
                            menu.disabled = !data['subtitle']['approved']
                        } else if(menu.type === 'sharing') {
                            menu.disabled = !data['approved']
                        }
                    }

                    return (
                        <MenuItemWrapper
                            key={index}
                            hoverColor={menu.hoverColor}
                            color={menu.color}
                            disabled={menu.disabled}
                        >
                            <MenuItem
                                onClick={e => {
                                    if (menu.disabled) {
                                        return e.stopPropagation()
                                    }
                                    setOpen(false)
                                    menu.onClick(e, data)
                                }}
                                icon={menu.icon}
                                color={menu.color}
                                hoverColor={menu.hoverColor}
                                text={menu.text}
                                disabled={menu.disabled}
                                disabledColor={menu.disabledColor}
                            />
                        </MenuItemWrapper>
                    )
                })
            }
        </PopupMenuStyled>
    )
}

PopupMenu.PropType = {
    color: PropTypes.string,
}

export default PopupMenu
