import PropTypes from "prop-types"
import React from 'react';
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Radio as SemanticRadio } from 'semantic-ui-react';

const RadioWrapper = styled(SemanticRadio)`
  
    &.ui.checkbox {
        label {
            color: ${colors.white};
        }

        label:before {
            border-color: ${colors.white};
            border-width: 2px;
            background-color: transparent;
        }
        
        
        input:focus~label {
            color: ${colors.white};
        }

        input:checked~label:before {
            background: transparent;
            border-color: ${colors.primary};
            border-width: 2px;
            color: ${colors.white};
        }
          

        input:focus:checked~label:before {
            background-color: transparent;
            color: ${colors.white};
        }

        input:checked~label:after {
            background: ${colors.primary};
            border-color: ${colors.primary};
            border-width: 2px;
            color: ${colors.white};
        }

        input:focus:checked~label:after {
            background-color: ${colors.primary};
            color: ${colors.white};
        }
    }
`

const Radio = (props) => {
    return (
        <RadioWrapper
            {...props}
        />
    )
}

Radio.propTypes = {
    
}

export default Radio