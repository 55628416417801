import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import * as Utils from '../../../utils/utils'
import { TextArea as TextAreaBase } from '../../CustomComponents/TextArea'
import PopupMenu from '../../CustomComponents/PopupMenu'
import { AttributeItem as AttributeItemBase } from '../../CustomComponents/AttributeItem'
import {
    Card as CardBase,
    CardMeta as CardMetaBase,
    CardTitle as CardTitleBase,
    CardContent as CardContentBase,
    CardFooter as CardFooterBase
} from '../../CustomComponents/Card'

const Card = styled(CardBase).attrs((props) => ({
    style: {
        width: `100%`,
        height: `285px`,
        backgroundColor: colors.card

    }
}))``

const CardMeta = styled(CardMetaBase).attrs((props) => ({
    style: {
        paddingTop: `0.7500em`,
        paddingBottom: `0`
    }
}))``

const CardTitle = styled(CardTitleBase).attrs((props) => ({

}))``

const CardContent = styled(CardContentBase).attrs((props) => ({
    style: {
        paddingRight: `24px`
    }
}))``

const CardFooter = styled(CardFooterBase).attrs((props) => ({
    style: {
        paddingTop: `0`,
        paddingBottom: `0`,
        height: "auto"
    }
}))``

const TextArea = styled(TextAreaBase).attrs((props) => ({
    style: {
        backgroundColor: `${colors.cardHover}`,
        minWidth: `100%`,
        minHeight: `84px`,
        maxHeight: `84px`,
    }
}))``

const AttributeItem = styled(AttributeItemBase).attrs((props) => ({
    style: {
        flex: `1`
    }
}))``

const MenuButton = styled.div`
	{
		display: flex;
		flex: 1;
		justify-content: flex-end;
		text-align: end;
		align-self: flex-end;
	}
`

const TextAreaWrapper = (props) => {
    const [text, setText] = useState(props.text)

    useEffect(() => {
        setText(props.text)
    }, [props.text])

    const handleOnTextAreaChange = (event, data) => {
        setText(data.value)
        props.onTextChangeListener(data.value)
    }

    return (
        <TextArea
            value={text}
            onChange={(event, data) => handleOnTextAreaChange(event, data)}
        />
    )
}

const SubtitleChosen = (props) => {
    const [subtitle] = useState({ ...props.subtitle })
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)

    useEffect(() => {
        if (subtitle) {
            const [startTimeFormat] = Utils.createTimeFormat(subtitle['subtitle']['startTime'])
            const [endTimeFormat] = Utils.createTimeFormat(subtitle['subtitle']['endTime'])
            setStartTime(startTimeFormat)
            setEndTime(endTimeFormat)
        }
    }, [{ ...props.subtitle }])


    const onTextChangeListener = (text) => {
        props.onSubtitleTextChange(text)
    }

    const onClickClearSubtitle = (event, subtitleData) => {
        props.onClickClearSubtitle(event, subtitleData);
    }

    const menuitems = [
        {
            icon: 'stop',
            color: colors.white,
            hoverColor: colors.primary,
            text: 'ไม่ต้องมีคำบรรยาย',
            onClick: onClickClearSubtitle
        }
    ]

    return (
        <Card {...props}>
            <CardMeta >
                <MenuButton onClick={(e) => { e.stopPropagation(); }}>
                    <PopupMenu data={subtitle} menuitems={menuitems} />
                </MenuButton>

            </CardMeta>
            <CardTitle>
                ช่องที่ {subtitle['index'] + 1} / {props.length}
            </CardTitle>
            <CardContent >
                <TextAreaWrapper text={props.text} onTextChangeListener={(text) => onTextChangeListener(text)} />
            </CardContent>

            <CardFooter>
                <AttributeItem icon="clock" color="white" value={`${startTime} - ${endTime}`} />
            </CardFooter>

            {props.children}
        </Card>
    )
}


export default SubtitleChosen