import React from 'react'
import VideoCard from '../../../CustomComponents/VideoCard'

const VideoList = (props) => {
    const videos = props.videos

    return (
        <>
            {
                videos && videos.map((video, key) => {
                    return (
                        <div
                            key={key}
                            style={{ padding: 0, height: "fit-content" }}
                            onClick={() => { props.onClickVideoListener(video) }}>

                            <VideoCard
                                video={video}
                                subtitleSlotsLength={video.subtitleSlotsLength}
                                menuitems={props.menuitems}      
                            />
                        </div>
                    )
                })
            }
        </>
    )
}

VideoList.propTypes = {

}

export default VideoList;