
import * as tf from '@tensorflow/tfjs';
import React, { useState, useRef, useEffect } from 'react'
import { useRecoilValue,useRecoilState } from 'recoil'
import {IsShowError} from "../../recoil/atoms"

export class VideoExtractor {
    NUMBER_OF_CHANNELS = 2
    DURATION = 170
    SAMPLE_RATE = 16000

    async extractAudio(video) {
        let loadVideoFile = await fetch(`https://preprocess-video-sa25i4aekq-df.a.run.app/?video=${video.url}`)
        // let loadVideoFile = await fetch(`http://localhost/?video=${video.url}`)
        if(!loadVideoFile.ok){
            let errorText = await loadVideoFile.text() 
            throw new Error(errorText);
        }
        const reader = loadVideoFile.body.getReader()
        return [reader];
    }
}


export class AudioSegment {
    MODEL_PATH = "/audio_segment_models/th_en_v0.2.0/model.json"
    THRESHORD = 0.9
    MODEL_SECS = 4.144 // secs
    SAMPLE_RATE = 16000
    BYTES_PER_SAMPLE = 4
    MIN_ACCEPTANCE_FRAME = 4

    async loadModel() {
        return new Promise(async (resolve) => {
            this.MODEL = await tf.loadGraphModel(this.MODEL_PATH)
            resolve()
        })
    }

    async predictFromReaderStream(reader) {
        let samples = this.SAMPLE_RATE * this.MODEL_SECS 
        let sampleBytes = samples * this.BYTES_PER_SAMPLE
        let allSampleBytes = 0 
        // let loadJsonFile = await fetch(`/net_input_ffmpeg.json`)
        // audioBuffer = await loadJsonFile.json();
        
        let segmentArray = [];

        tf.engine().startScope()
        let tempChunk = new Uint8Array(0)
        let audioChunk = new Uint8Array(sampleBytes)
        while(true){
            let { done, value: subAudioBuffer} = await reader.read()
            let subAudioBufferFloat
            if (done) {
                subAudioBufferFloat = new Uint8Array(0)
            }
            else subAudioBufferFloat = subAudioBuffer
            let i = 0
            while(i <= subAudioBufferFloat.length){
                let samplesBytesNeeded = sampleBytes
                let sampleOffset = 0
                if(tempChunk.length > 0){
                    samplesBytesNeeded -= tempChunk.length
                    sampleOffset = tempChunk.length
                    audioChunk.set(tempChunk,0)
                    tempChunk = new Uint8Array(0)
                }
                let newAudioChunk = subAudioBufferFloat.slice(i, i + samplesBytesNeeded)
                allSampleBytes += newAudioChunk.length
                if(newAudioChunk.length < samplesBytesNeeded){
                    if(!done){
                        tempChunk = new Uint8Array(sampleOffset + newAudioChunk.length)
                        let oldTemp = audioChunk.slice(0, sampleOffset)
                        if (oldTemp.length > 0)
                            tempChunk.set(oldTemp,0)
                        tempChunk.set(newAudioChunk, sampleOffset)
                    }else{
                        let lastChunk = new Uint8Array(samplesBytesNeeded)
                        lastChunk.set(newAudioChunk, 0)
                        newAudioChunk = lastChunk
                    }
                }
                
                if(newAudioChunk.length == samplesBytesNeeded){
                    audioChunk.set(newAudioChunk,sampleOffset)
                    let audioChunkFloat =  new Float32Array(audioChunk.buffer)
                    const audioTensor = await tf.tensor1d(audioChunkFloat)
                    const audioTensorExpanded = audioTensor.expandDims(0)
                    const segment = this.MODEL.predict(audioTensorExpanded)
                    const newSegmentArray = segment.arraySync()[0]
                    // console.log(newSegmentArray)
                    segmentArray = segmentArray.concat(newSegmentArray)
                    audioTensor.dispose()
                    audioTensorExpanded.dispose()
                    segment.dispose()
                }

                i += samplesBytesNeeded
            }
            if (done)
                break;
        }
      
        segmentArray = segmentArray.map((segment, index) => {
            return { confidence: segment[0][1], time: (this.MODEL_SECS / 16) * index }
        })
        let duration = allSampleBytes / this.BYTES_PER_SAMPLE / this.SAMPLE_RATE 
        let noHumanVoiceMoments = []
        let noHumanVoiceMomentsTemp = []
        let confidence = []
        segmentArray.map((segment) => {
            confidence.push(segment['confidence'])
            if (segment['confidence'] <= this.THRESHORD && segment['time'] < duration) {
                noHumanVoiceMomentsTemp.push(segment)
            } else {
                if (noHumanVoiceMomentsTemp.length >= this.MIN_ACCEPTANCE_FRAME && noHumanVoiceMomentsTemp[0] !== undefined &&  noHumanVoiceMomentsTemp[noHumanVoiceMomentsTemp.length - 1] !== undefined )  {
                    noHumanVoiceMoments.push([noHumanVoiceMomentsTemp[0], noHumanVoiceMomentsTemp[noHumanVoiceMomentsTemp.length - 1]])
                }
                noHumanVoiceMomentsTemp = []
            } 
        })

        if (noHumanVoiceMomentsTemp.length >= this.MIN_ACCEPTANCE_FRAME && noHumanVoiceMomentsTemp[0] !== undefined &&  noHumanVoiceMomentsTemp[noHumanVoiceMomentsTemp.length - 1] !== undefined)  {
            noHumanVoiceMoments.push([noHumanVoiceMomentsTemp[0], noHumanVoiceMomentsTemp[noHumanVoiceMomentsTemp.length - 1]])
        }
        noHumanVoiceMomentsTemp = []
        // console.log(JSON.stringify(confidence))
        tf.engine().endScope()

        return [segmentArray, noHumanVoiceMoments, duration]
    }

    releaseModel() {
        this.MODEL.dispose()
    }
}