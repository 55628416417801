import React from 'react'
import PropTypes from 'prop-types'
import {
  selectSlot,
  trashCan,
  block,
  ellipsisVertical,
  checkList,
  clock,
  hourglass,
  stack,
  update,
  check,
  video,
  info,
  hamburger,
  add,
  upload,
  download,
  calendar,
  stop,
  eye,
  eyeslash,
  enter,
  exit,
  expand,
  smallLayout,
  largeLayout,
  arrowUp,
  arrowDown,
  errorInfo
} from '../../../constants/icon-inventory'

const icons = { selectSlot, trashCan, block, ellipsisVertical, checkList, clock, hourglass, stack, update, check, video, info, hamburger, add, upload, download, calendar, stop, eye, eyeslash, enter, exit, expand, smallLayout, largeLayout, arrowUp, arrowDown, errorInfo }

const Icon = ({ name, color }) => (
  <>
    {icons[name](color)}
  </>
)

Icon.PropType = {
  props: {
    name: PropTypes.string,
    color: PropTypes.string
  }
}

export default Icon