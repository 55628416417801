import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'

const AttributeIcon = styled.div`
    {
        display: flex;
        min-width: 48px;
        min-height: 48px;
        justify-content: center;
        align-items: center;
        text-align: center
    }
`

const AttributeValue = styled.div`
    {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Sarabun-Light;
        font-size: 12px;
    }
`

const AttributeItemWrapper = styled.div`
    {
        display: flex;
        flex-direction: row;
    }
`

const AttributeItem = (props) => {
    return (
        <AttributeItemWrapper {...props}>
            <AttributeIcon>
                <Icon name={props.icon} color={props.color} />
            </AttributeIcon>
            <AttributeValue>
                {props.value}
            </AttributeValue>
        </AttributeItemWrapper>
    )

}

export default AttributeItem;