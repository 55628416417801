import { selector } from 'recoil'
import { ROLES } from '../constants/authenticate';
import { PageState, Role, IsLogin, NavSections, UniqueId } from './atoms'

export const pageStateSelector = selector({
    key: "pageState",
    get: ({ get }) => {
        const page = get(PageState)
        return page;
    },
})

export const isAdminSelector = selector({
    key: "isAdmin",
    get: ({ get }) => {
        const role = get(Role)
        console.log('isAdmin selectors', role, role === ROLES.ADMIN)
        return role === ROLES.ADMIN
    }
})

export const isLoginSelector = selector({
    key: "isLogin",
    get: ({ get }) => {
        return get(IsLogin)
    }
})

export const navSectionsSelector = selector({
    key: "navSections",
    get: ({ get }) => {
        return get(NavSections)
    }
})


export const uniqueIdSelector= selector({
    key: "uniqueId",
    get: ({ get }) => {
        return get(UniqueId)
    }
})