import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Modal, Form } from 'semantic-ui-react'
import Radio from '../Radio'
import InputField from '../InputField'
import TextArea from '../TextArea'
import Button from '../Button'
import { v1 as uuidv1 } from 'uuid'

const ModalWrapper = styled(Modal)`
    &.ui.modal {
        @media only screen and (min-width: 1920px) {
            width: 708px;
        }
        background-color: ${colors.background};
        border-width: 2px;
        border-style: solid;
        border-color: ${colors.lightGrey};
        border-radius: 15px;
      
        .header {
            border-radius: 15px;
      
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
        }
        .content {
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
        }

        .actions {
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
            border-radius: 15px;      
        }
    }
`

const TitleWrapper = styled.div`
    {
        color: ${colors.lightGrey};
        font-family: Prompt-Regular;
        font-size: 1em;
        
    }
`

const TextAreaStyle = {
    height: "15vh",
    backgroundColor: colors.cardHover,
    borderRadius: "12px",
    color: colors.white
}

const CreateVideoCard = (props) => {
    const { createMode, video } = props

    const [videoEndpoint, setVideoEndpoint] = useState('YOUTUBE')
    const [url, setURL] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [thumbnail, setThumbnail] = useState('/thumbnail/no_thumbnail.jpg')
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (video) {
            setURL(video['url'])
            setTitle(video['title'])
            setDescription(video['description'])
        }
    }, [video])

    const setVideo = () => {
        //remove &ab_channel... to prevent playback error
        var vdoURL = url;
        var splitURL = vdoURL.split("&ab_channel")[0];
        if (splitURL.includes("playlist") || splitURL.includes("start_radio"))
            splitURL = ""

        let videoData = {
            subtitleId: uuidv1(),
            url: splitURL,
            title: title,
            description: description,
            videoEndpoint: videoEndpoint,
            thumbnail: thumbnail,
            duration: duration,
            approved: false,
            published: false,
            createdDate: Date.now()
        }

        props.onCreateVideo(videoData)
    }

    const updateVideo = () => {
        let videoData = {
            title: title,
            description: description,
        }
        console.log(videoData)
        props.onUpdateVideo(videoData)
    }

    return (
        <ModalWrapper
            {...props}
        >
            <Modal.Header>
                {createMode ? 'เพิ่มวิดีโอ' : 'แก้ไขข้อมูลวิดีโอ'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <TitleWrapper>Link URL</TitleWrapper>
                        <InputField
                            defaultValue={!createMode && video ? video['url'] : null}
                            disabled={!createMode}
                            onChange={(event, { value }) => { setURL(value) }} />
                    </Form.Field>
                    {createMode &&
                        <Form.Field>
                            <Radio
                                label='YouTube'
                                name='radioGroup'
                                value='YOUTUBE'
                                checked={videoEndpoint === 'YOUTUBE'}
                                onChange={(event, { value }) => setVideoEndpoint(value)}
                            />
                        </Form.Field>
                    }

                    <Form.Field>
                        <TitleWrapper>หัวข้อเรื่อง</TitleWrapper>
                        <InputField
                            defaultValue={!createMode && video ? video['title'] : null}
                            onChange={(event, { value }) => { setTitle(value) }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <TitleWrapper>คำอธิบายวิดีโอ</TitleWrapper>
                        <TextArea
                            style={TextAreaStyle}
                            defaultValue={!createMode && video ? video['description'] : null}
                            onChange={(event, { value }) => { setDescription(value) }} />
                    </Form.Field>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button negative onClick={props.onClose}>
                    ยกเลิก
                </Button>
                {
                    createMode ?
                        (
                            <Button positive onClick={() => { setVideo() /*props.onCreateVideo*/ }} >
                                เพิ่มวิดีโอ
                            </Button>
                        ) :
                        (
                            <Button positive onClick={() => { updateVideo() /*props.onCreateVideo*/ }} >
                                แก้ไข
                            </Button>
                        )
                }

            </Modal.Actions>
        </ModalWrapper>
    )
}

export default CreateVideoCard