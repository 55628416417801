import React from "react"

export const selectSlot = (color) => (
    <svg width="12" height="16" viewBox="0 0 12 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M3.2381 6.2781V3.42857C3.2381 2.37714 4.09143 1.52381 5.14286 1.52381C6.19429 1.52381 7.04762 2.37714 7.04762 3.42857V6.2781C7.96952 5.66095 8.57143 4.61714 8.57143 3.42857C8.57143 1.53143 7.04 0 5.14286 0C3.24571 0 1.71429 1.53143 1.71429 3.42857C1.71429 4.61714 2.31619 5.66095 3.2381 6.2781ZM10.7352 9.80571L7.27619 8.08381C7.14667 8.03048 7.00952 8 6.86476 8H6.28571V3.42857C6.28571 2.79619 5.77524 2.28571 5.14286 2.28571C4.51048 2.28571 4 2.79619 4 3.42857V11.6114C1.25714 11.0324 1.30286 11.04 1.20381 11.04C0.967619 11.04 0.754286 11.139 0.601905 11.2914L0 11.901L3.76381 15.6648C3.96952 15.8705 4.25905 16 4.57143 16H9.74476C10.3162 16 10.7581 15.581 10.8419 15.0248L11.4133 11.0095C11.421 10.9562 11.4286 10.9029 11.4286 10.8571C11.4286 10.3848 11.139 9.97333 10.7352 9.80571Z" fill={color || 'none'} />
    </svg>)

export const trashCan = (color) => (
    <svg width="13" height="16" viewBox="0 0 13 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M0.888889 14.2222C0.888889 15.2 1.68889 16 2.66667 16H9.77778C10.7556 16 11.5556 15.2 11.5556 14.2222V3.55556H0.888889V14.2222ZM2.66667 5.33333H9.77778V14.2222H2.66667V5.33333ZM9.33333 0.888889L8.44444 0H4L3.11111 0.888889H0V2.66667H12.4444V0.888889H9.33333Z" fill={color || 'none'} />
    </svg>)


export const block = (color) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 6.52 2.104 5.16 2.952 4.08L11.92 13.048C10.84 13.896 9.48 14.4 8 14.4ZM13.048 11.92L4.08 2.952C5.16 2.104 6.52 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 9.48 13.896 10.84 13.048 11.92Z" fill={color || 'none'} />
    </svg>
)

export const ellipsisVertical = (color) => (
    <svg width="4" height="16" viewBox="0 0 4 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z" fill={color || 'none'} />
    </svg>
)

export const checkList = (color) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M4.92717 5.71429L3.16735 4L1.99414 5.14286L4.92717 8L11.9664 1.14286L10.7932 0L4.92717 5.71429Z" fill={color || "none"} />
        <path d="M8.22562 1.1H2.19391C1.58808 1.1 1.09695 1.59249 1.09695 2.2V8.8C1.09695 9.40751 1.58808 9.9 2.19391 9.9H8.77562C9.38145 9.9 9.87258 9.40751 9.87258 8.8V4.54848C9.87258 4.24556 10.1181 4 10.4211 4C10.724 4 10.9695 4.24556 10.9695 4.54848V8.8C10.9695 10.015 9.98728 11 8.77562 11H2.19391C0.982245 11 0 10.015 0 8.8V2.2C0 0.984973 0.982245 0 2.19391 0H8.22562C8.52938 0 8.77562 0.246243 8.77562 0.55C8.77562 0.853757 8.52938 1.1 8.22562 1.1Z" fill={color || "none"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7395 1.1H15.1578C14.5519 1.1 14.0608 1.59249 14.0608 2.2V8.8C14.0608 9.40751 14.5519 9.9 15.1578 9.9H21.7395C22.3453 9.9 22.8364 9.40751 22.8364 8.8V2.2C22.8364 1.59249 22.3453 1.1 21.7395 1.1ZM15.1578 0C13.9461 0 12.9639 0.984973 12.9639 2.2V8.8C12.9639 10.015 13.9461 11 15.1578 11H21.7395C22.9512 11 23.9334 10.015 23.9334 8.8V2.2C23.9334 0.984974 22.9512 0 21.7395 0H15.1578Z" fill={color || "none"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.77562 14.1H2.19391C1.58808 14.1 1.09695 14.5925 1.09695 15.2V21.8C1.09695 22.4075 1.58808 22.9 2.19391 22.9H8.77562C9.38145 22.9 9.87258 22.4075 9.87258 21.8V15.2C9.87258 14.5925 9.38145 14.1 8.77562 14.1ZM2.19391 13C0.982245 13 0 13.985 0 15.2V21.8C0 23.015 0.982245 24 2.19391 24H8.77562C9.98728 24 10.9695 23.015 10.9695 21.8V15.2C10.9695 13.985 9.98728 13 8.77562 13H2.19391Z" fill={color || "none"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7395 14.1H15.1578C14.5519 14.1 14.0608 14.5925 14.0608 15.2V21.8C14.0608 22.4075 14.5519 22.9 15.1578 22.9H21.7395C22.3453 22.9 22.8364 22.4075 22.8364 21.8V15.2C22.8364 14.5925 22.3453 14.1 21.7395 14.1ZM15.1578 13C13.9461 13 12.9639 13.985 12.9639 15.2V21.8C12.9639 23.015 13.9461 24 15.1578 24H21.7395C22.9512 24 23.9334 23.015 23.9334 21.8V15.2C23.9334 13.985 22.9512 13 21.7395 13H15.1578Z" fill={color || "none"} />
    </svg>
)

export const clock = (color) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9548 0C5.34914 0 0 5.376 0 12C0 18.624 5.34914 24 11.9548 24C18.5724 24 23.9335 18.624 23.9335 12C23.9335 5.376 18.5724 0 11.9548 0ZM11.9668 21.6C6.67745 21.6 2.39335 17.304 2.39335 12C2.39335 6.696 6.67745 2.4 11.9668 2.4C17.2561 2.4 21.5402 6.696 21.5402 12C21.5402 17.304 17.2561 21.6 11.9668 21.6Z" fill={color || 'none'} />
        <path d="M12.5645 6H10.7695V13.2L17.0521 16.98L17.9496 15.504L12.5645 12.3V6Z" fill={color || 'none'} />
    </svg>
)

export const hourglass = (color) => (
    <svg width="20" height="24" viewBox="0 0 20 24" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M19 22H17.2372H2.76276H1C0.48949 22 0 22.4333 0 23C0 23.5667 0.48949 24 1 24H19C19.5105 24 20 23.5667 20 23C20 22.4333 19.5105 22 19 22Z" fill={color || 'none'} />
        <path d="M1 2H2.76276H17.2372H19C19.5105 2 20 1.56667 20 1C20 0.433333 19.5105 0 19 0H1C0.48949 0 0 0.433333 0 1C0 1.56667 0.48949 2 1 2Z" fill={color || 'none'} />
        <path d="M17 16.8057C17 16.5512 16.8838 16.2968 16.6805 16.1272L11.1037 11.5L16.6805 7.59011C16.8838 7.42049 17 7.19435 17 6.91166V4H3V6.91166C3 7.16608 3.11618 7.42049 3.3195 7.59011L8.86722 11.5L3.3195 16.1272C3.11618 16.2968 3 16.523 3 16.8057V20H17V16.8057Z" fill={color || 'none'} />
    </svg>
)

export const stack = (color) => (
    <svg width="23" height="24" viewBox="0 0 23 24" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3141 20.8159L2.0388 13.6046L0 15.1904L11.3267 24L22.6534 15.1904L20.602 13.592L11.3141 20.8159ZM11.3267 17.6193L20.5894 10.408L22.6534 8.80965L11.3267 0L0 8.80965L2.05139 10.408L11.3267 17.6193Z" fill={color || 'none'} />
    </svg>
)

export const update = (color) => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 14.1818L16.3636 9.81818H13.0909V0H10.9091V9.81818H7.63636L12 14.1818ZM21.8182 0H15.2727V2.17091H21.8182V17.4764H2.18182V2.17091H8.72727V0H2.18182C0.981818 0 0 0.981818 0 2.18182V17.4545C0 18.6545 0.981818 19.6364 2.18182 19.6364H21.8182C23.0182 19.6364 24 18.6545 24 17.4545V2.18182C24 0.981818 23.0182 0 21.8182 0Z" fill={color || 'none'} />
    </svg>
)

export const check = (color) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M14.59 5.58L8 12.17L4.41 8.59L3 10L8 15L16 7L14.59 5.58ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill={color || 'none'} />
    </svg>
)

export const video = (color) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M2.4 4.8H0V21.6C0 22.92 1.08 24 2.4 24H19.2V21.6H2.4V4.8ZM21.6 0H7.2C5.88 0 4.8 1.08 4.8 2.4V16.8C4.8 18.12 5.88 19.2 7.2 19.2H21.6C22.92 19.2 24 18.12 24 16.8V2.4C24 1.08 22.92 0 21.6 0ZM12 15V4.2L19.2 9.6L12 15Z" fill={color || 'none'} />
    </svg>
)

export const info = (color) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8 5.5C10.5239 5.5 10.3 5.72386 10.3 6L10.3 8.4C10.3 8.67614 10.5239 8.9 10.8 8.9L13.2 8.9C13.4761 8.9 13.7 8.67614 13.7 8.4L13.7 6C13.7 5.72386 13.4761 5.5 13.2 5.5L10.8 5.5ZM10.8 10.3C10.5239 10.3 10.3 10.5239 10.3 10.8L10.3 18C10.3 18.2761 10.5239 18.5 10.8 18.5L13.2 18.5C13.4761 18.5 13.7 18.2761 13.7 18L13.7 10.8C13.7 10.5239 13.4761 10.3 13.2 10.3L10.8 10.3ZM23.5 12C23.5 18.3479 18.3479 23.5 12 23.5C5.65214 23.5 0.5 18.3479 0.500001 12C0.500002 5.65214 5.65214 0.499998 12 0.499999C18.3479 0.5 23.5 5.65214 23.5 12Z" stroke={color || 'none'} strokeLinejoin="round" />
    </svg>
)

export const hamburger = (color) => (
    <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 9H24V6H0V9ZM0 15H24V12H0V15ZM0 3H24V0H0V3Z" fill={color || 'none'} />
    </svg>
)

export const add = (color) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36 20.5714H20.5714V36H15.4286V20.5714H0V15.4286H15.4286V0H20.5714V15.4286H36V20.5714Z" fill={color || 'none'} />
    </svg>
)

export const upload = (color) => (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14V16H0V14H8ZM3 3.99V12H5V3.99H8L4 0L0 3.99H3Z" fill={color || 'none'} />
    </svg>
)

export const download = (color) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 13.75V17.5H2.5V13.75H0V17.5C0 18.875 1.125 20 2.5 20H17.5C18.875 20 20 18.875 20 17.5V13.75H17.5ZM16.25 8.75L14.4875 6.9875L11.25 10.2125V0H8.75V10.2125L5.5125 6.9875L3.75 8.75L10 15L16.25 8.75Z" fill={color || 'none'} />
    </svg>
)

export const calendar = (color) => (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.20293 10.8H4.80293V13.2H7.20293V10.8ZM12.0029 10.8H9.60293V13.2H12.0029V10.8ZM16.8029 10.8H14.4029V13.2H16.8029V10.8ZM19.2029 2.4H18.0029V0H15.6029V2.4H6.00293V0H3.60293V2.4H2.40293C1.07093 2.4 0.0149297 3.48 0.0149297 4.8L0.00292969 21.6C0.00292969 22.92 1.07093 24 2.40293 24H19.2029C20.5229 24 21.6029 22.92 21.6029 21.6V4.8C21.6029 3.48 20.5229 2.4 19.2029 2.4ZM19.2029 21.6H2.40293V8.4H19.2029V21.6Z" fill={color || 'none'} />
    </svg>
)

export const stop = (color) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7.2V8.8H12V7.2H4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" fill={color || 'none'} />
    </svg>
)

export const eye = (color) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0932 19.7751C10.3087 19.7751 7.46912 18.4725 4.82866 16.34C2.91409 14.7937 1.18652 12.8165 1.18652 12.1981C1.18652 11.5797 2.91409 9.60249 4.82866 8.05621C7.46912 5.92365 10.3087 4.62109 13.0932 4.62109C15.8778 4.62109 18.7174 5.92365 21.3578 8.05621C23.2724 9.60249 25 11.5797 25 12.1981C25 12.8165 23.2724 14.7937 21.3578 16.34C18.7174 18.4725 15.8778 19.7751 13.0932 19.7751ZM13.0932 18.6927C15.5886 18.6927 18.2122 17.4892 20.6777 15.4979C21.6045 14.7494 22.4518 13.9299 23.116 13.1697C23.3894 12.8567 23.6156 12.5713 23.7708 12.3475C23.8562 12.2242 23.9175 12.1084 23.9175 12.1981C23.9175 12.2878 23.8562 12.172 23.7708 12.0487C23.6156 11.8249 23.3894 11.5395 23.116 11.2265C22.4518 10.4663 21.6045 9.64679 20.6777 8.89829C18.2122 6.907 15.5886 5.70352 13.0932 5.70352C10.5979 5.70352 7.97432 6.907 5.50876 8.89829C4.58199 9.64679 3.73472 10.4663 3.07049 11.2265C2.79709 11.5395 2.57087 11.8249 2.41572 12.0487C2.33025 12.172 2.26895 12.2878 2.26895 12.1981C2.26895 12.1084 2.33025 12.2242 2.41572 12.3475C2.57087 12.5713 2.79709 12.8567 3.07049 13.1697C3.73472 13.9299 4.58199 14.7494 5.50876 15.4979C7.97432 17.4892 10.5979 18.6927 13.0932 18.6927ZM13.0932 16.5278C10.702 16.5278 8.76353 14.5893 8.76353 12.1981C8.76353 9.80686 10.702 7.86838 13.0932 7.86838C15.4845 7.86838 17.423 9.80686 17.423 12.1981C17.423 14.5893 15.4845 16.5278 13.0932 16.5278ZM13.0932 15.4454C14.8867 15.4454 16.3405 13.9915 16.3405 12.1981C16.3405 10.4047 14.8867 8.95081 13.0932 8.95081C11.2998 8.95081 9.84596 10.4047 9.84596 12.1981C9.84596 13.9915 11.2998 15.4454 13.0932 15.4454Z" fill={color || 'none'} />
    </svg>
)

export const eyeslash = (color) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0932 19.7751C10.3087 19.7751 7.46912 18.4725 4.82866 16.34C2.91409 14.7937 1.18652 12.8165 1.18652 12.1981C1.18652 11.5797 2.91409 9.60249 4.82866 8.05621C7.46912 5.92365 10.3087 4.62109 13.0932 4.62109C15.8778 4.62109 18.7174 5.92365 21.3578 8.05621C23.2724 9.60249 25 11.5797 25 12.1981C25 12.8165 23.2724 14.7937 21.3578 16.34C18.7174 18.4725 15.8778 19.7751 13.0932 19.7751ZM13.0932 18.6927C15.5886 18.6927 18.2122 17.4892 20.6777 15.4979C21.6045 14.7494 22.4518 13.9299 23.116 13.1697C23.3894 12.8567 23.6156 12.5713 23.7708 12.3475C23.8562 12.2242 23.9175 12.1084 23.9175 12.1981C23.9175 12.2878 23.8562 12.172 23.7708 12.0487C23.6156 11.8249 23.3894 11.5395 23.116 11.2265C22.4518 10.4663 21.6045 9.64679 20.6777 8.89829C18.2122 6.907 15.5886 5.70352 13.0932 5.70352C10.5979 5.70352 7.97432 6.907 5.50876 8.89829C4.58199 9.64679 3.73472 10.4663 3.07049 11.2265C2.79709 11.5395 2.57087 11.8249 2.41572 12.0487C2.33025 12.172 2.26895 12.2878 2.26895 12.1981C2.26895 12.1084 2.33025 12.2242 2.41572 12.3475C2.57087 12.5713 2.79709 12.8567 3.07049 13.1697C3.73472 13.9299 4.58199 14.7494 5.50876 15.4979C7.97432 17.4892 10.5979 18.6927 13.0932 18.6927ZM13.0932 16.5278C10.702 16.5278 8.76353 14.5893 8.76353 12.1981C8.76353 9.80686 10.702 7.86838 13.0932 7.86838C15.4845 7.86838 17.423 9.80686 17.423 12.1981C17.423 14.5893 15.4845 16.5278 13.0932 16.5278ZM13.0932 15.4454C14.8867 15.4454 16.3405 13.9915 16.3405 12.1981C16.3405 10.4047 14.8867 8.95081 13.0932 8.95081C11.2998 8.95081 9.84596 10.4047 9.84596 12.1981C9.84596 13.9915 11.2998 15.4454 13.0932 15.4454Z" fill={color || 'none'} />
        <path d="M1 24.7388L24.7388 1" stroke={color || 'none'} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const enter = (color) => (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8 4.8L9.12 6.48L12.24 9.6H0V12H12.24L9.12 15.12L10.8 16.8L16.8 10.8L10.8 4.8ZM21.6 19.2H12V21.6H21.6C22.92 21.6 24 20.52 24 19.2V2.4C24 1.08 22.92 0 21.6 0H12V2.4H21.6V19.2Z" fill={color || 'none'} />
    </svg>
)

export const exit = (color) => (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4003 19.2H11.8003V21.6H21.4003C22.7203 21.6 23.8003 20.52 23.8003 19.2V2.4C23.8003 1.08 22.7203 0 21.4003 0H11.8003V2.4H21.4003V19.2Z" fill={color || 'none'} />
        <path d="M5.99999 16.7988L7.67999 15.1188L4.55999 11.9988L16.8 11.9988L16.8 9.59883L4.55999 9.59883L7.67999 6.47883L5.99999 4.79883L-1.28269e-05 10.7988L5.99999 16.7988Z" fill={color || 'none'} />
    </svg>
)

export const expand = (color) => (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.64706H4.70588V0H0V5.64706ZM0 12.2353H4.70588V6.58824H0V12.2353ZM5.64706 12.2353H10.3529V6.58824H5.64706V12.2353ZM11.2941 12.2353H16V6.58824H11.2941V12.2353ZM5.64706 5.64706H10.3529V0H5.64706V5.64706ZM11.2941 0V5.64706H16V0H11.2941Z" fill={color || 'none'} />
    </svg>
)

export const smallLayout = (color) => (
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 14V12H9V14H0Z" fill={color || 'none'} />
        <path d="M0 17V15H9V17H0Z" fill={color || 'none'} />
        <path d="M0 20V18H9V20H0Z" fill={color || 'none'} />
        <path d="M9 0H0V9H9V0Z" fill={color || 'none'} />
        <path d="M12 5H17V0H12V5ZM19.5 20H24.5V15H19.5V20ZM12 20H17V15H12V20ZM12 12.5H17V7.5H12V12.5ZM19.5 12.5H24.5V7.5H19.5V12.5ZM27 0V5H32V0H27ZM19.5 5H24.5V0H19.5V5ZM27 12.5H32V7.5H27V12.5ZM27 20H32V15H27V20Z" fill={color || 'none'} />
    </svg>
)

export const largeLayout = (color) => (
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.4339 20H0V0L24.4339 0V20ZM26.9559 5H32V0H26.9559V5ZM26.9559 20H32V15H26.9559V20ZM26.9559 12.5H32V7.5H26.9559V12.5Z" fill={color || 'none'} />
    </svg>
)

export const arrowUp = (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" fill={color || 'none'} />
    </svg>
)

export const arrowDown = (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill={color || 'none'} />
    </svg>
)

export const errorInfo = () => (
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38 0C17.024 0 0 17.024 0 38C0 58.976 17.024 76 38 76C58.976 76 76 58.976 76 38C76 17.024 58.976 0 38 0ZM41.8 57H34.2V49.4H41.8V57ZM41.8 41.8H34.2L34.2 19H41.8L41.8 41.8Z" fill="#F6CA06" />
    </svg>
)