import React, { useState } from 'react'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Modal } from 'semantic-ui-react'

const ModalWrapper = styled(Modal)`
    &.ui.modal {
        background-color: ${colors.background};
        border-width: 2px;
        z-index: 2100;
      
        .header {
            border-radius: 15px;
      
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
        }
        .content {
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
            border-radius: 15px;
        }

        .actions {
            background-color: ${colors.background};
            color: ${colors.white};
            font-family: Prompt;
            border-radius: 15px;      
        }
    }
`

const ErrorCard =(props) =>{
    const[showDetail,setShowDetail] = useState(false);
    const toggleDetail = () =>{
        setShowDetail(showDetail?false:true);
    }

    return (
        <ModalWrapper
        onClose = {props.onClose}
        onOpen = {props.onOpen}
        size = {props.size}
        open = {props.open}>
            <Modal.Header>
            กรุณาตรวจสอบใหม่อีกครั้ง
            </Modal.Header>
            <Modal.Content>
            เนื่องจากระบบไม่สามารถรองรับ Link URL ดังกล่าวได้
            <p/>
            <a onClick={toggleDetail}>รายละเอียด
            {showDetail?<i class="caret down icon"/>:<i class="caret left icon"/>}
            </a><p/>
            {showDetail?<p>{props.errorText}</p>:<></>}
            </Modal.Content>
            
        </ModalWrapper>
    )
}

export default ErrorCard;