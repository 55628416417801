import { AUTHENTICATION_ENDPOINT } from "../../../../constants/authenticate"

export default function authenticate(password) {
    return fetch(AUTHENTICATION_ENDPOINT  , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password })
    })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log('Checkick password error : ', error)
        })
}
