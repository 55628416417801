import React, { useState, useEffect } from "react"
import { useRecoilState, useRecoilValue } from 'recoil'
import Login from "../../CustomComponents/ConfirmedPage/Login"
import { Role, IsLogin } from '../../../recoil/atoms'
import PropTypes from "prop-types"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const [role, setRole] = useRecoilState(Role);
    const [isLogin, setLogin] = useRecoilState(IsLogin);
    const [openLoginDialog, setOpenLoginDialog] = useState(false)

    if (location.pathname.match(/^\/admin/)) {

        useEffect(() => {
            if (role && !isLogin) {
                setOpenLoginDialog(true)
            } else {
                setOpenLoginDialog(false)
            }
        }, [openLoginDialog])

        return (
            <>
                <Component {...rest} />
                <Login open={!isLogin} />
            </>

        )
    }

    return <Component {...rest} />
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
}

export default PrivateRoute