import React, { useState, useEffect } from 'react'
import { Modal as ModalBase } from 'semantic-ui-react'
import colors from '../../../styles/global.scss'
import styled from 'styled-components'
import SubtitleChosen from './SubtitleChosen';
import SubtitleChoice from './SubtitleChoice';
import Button from '../../CustomComponents/Button';

const Modal = styled(ModalBase)`
    &.ui.modal {
        min-width:1134px;
        box-shadow: none;
        &, * {
            background: transparent
        }

        .header {
            .ui.card {
                width: 100%;
                margin: 0;
            }
        }

        .content {
            display: flex;
            flex-flow: row wrap;
            padding: 1.25rem 1.5rem;
            max-height: 65vh;
            width: 101%;
            overflow-y: scroll;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            padding-right: 24px;
            padding-bottom: 24px;
            .ui.button {
                width: 80px;
                height: 36px;
                padding: 0;
            }
        }
    }
`


function SubtitleEditorPage(props) {
    const [_, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [subtitle, setSubtitle] = useState(null)
    const [text, setText] = useState("")
    const { open } = props;

    useEffect(() => {
        setText(props.subtitle['subtitle']['text'])
    }, [{...props.text}])

    useEffect(() => {
        setText(text)
    }, [text])

    useEffect(() => {
        setSubtitle(props.subtitle)
    }, [{ ...props.subtitle }])

    const onSaveSubtitle = (subtitle) => {
        subtitle['subtitle']['text'] = text
        setSubtitle({ ...subtitle })
        props.onSubtitleUpdated(subtitle)
    }

    const onSubtitleTextChange = (text) => {
        setText(text)
    }

    const onClickClearSubtitle = (e, subtitleData) => {
        setText("")
    }

    const onClickSelectSubtitle = (e, subtitleData) => {
        setText(subtitleData['subtitle']['text'])
    }

    const onClickRemoveSubtitle = (e, subtitleData) => {
        props.onDeleteSubtitleCollection(subtitleData)
        forceUpdate()
    }

    const menuitems = [
        {
            icon: 'selectSlot',
            color: colors.white,
            hoverColor: colors.primary,
            text: 'เลือกคำบรรยายตัวเลือกนี้',
            onClick: onClickSelectSubtitle
        },
        {
            icon: 'trashCan',
            color: colors.delete,
            hoverColor: colors.delete,
            text: 'ลบคำบรรยายตัวเลือกนี้',
            onClick: onClickRemoveSubtitle
        }
    ]
    return (
        <Modal open={open}>
            <Modal.Header>
                <SubtitleChosen
                    subtitle={subtitle}
                    text={text}
                    length={props.length}
                    onSubtitleTextChange={(text) => onSubtitleTextChange(text)}
                    onClickClearSubtitle={onClickClearSubtitle}
                >
                    <Modal.Actions>
                        <Button negative onClick={() => { setText(props.subtitle['subtitle']['text']); props.onClose() }}>
                            ยกเลิก
                        </Button>
                        <Button positive onClick={() => onSaveSubtitle(subtitle)}>
                            บันทึก
                        </Button>
                    </Modal.Actions>
                </SubtitleChosen>
            </Modal.Header>
            <Modal.Content>
                {
                    props.subtitlesCollection &&
                    props.subtitlesCollection.map((_, index) => {
                        return <SubtitleChoice
                            key={index}
                            index={index}
                            subtitle={_}
                            length={props.subtitlesCollection.length}
                            menuitems={menuitems}
                            onClickSelectSubtitle={onClickSelectSubtitle}
                        />
                    })
                }
            </Modal.Content>
        </Modal>
    )
}

export default SubtitleEditorPage