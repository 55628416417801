import React, { useEffect } from 'react'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'

import { Grid } from "semantic-ui-react"
import { useRecoilState } from 'recoil'
import { NavSections } from './../../../recoil/atoms'

const Container = styled.div`
    {
        padding-left: 6%;
    }
`

const TitleWrapper = styled.div`
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white};
    padding-top: 1rem;
    padding-bottom:  0.5rem;
`
const Img = styled.img`
    width: 88%;
    height: auto;
`
const Content = styled.div`
    font-family: "Sarabun-Medium";
    font-size: 1.1rem;
    color: ${colors.white};
    padding-right:  40%;
`

const About = () => {
    const [, setNavSelection] = useRecoilState(NavSections)

    useEffect(() => {
        setNavSelection((oldSelection) => [
            { key: 'About', content: 'เกี่ยวกับ', link: true }
        ])
    }, [])

    return (
        <Container>
            <TitleWrapper>
                เกี่ยวกับ “โวหาร”
            </TitleWrapper>
            <Grid stackable >
                <Grid.Column width={8} >
                    <Img src="/about/image_1.jpg" />
                    <Img src="/about/image_2.jpg" />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Content>

                        “โวหาร” <br /><br />
                        <p>
                            แอพพลิเคชั่นเสียงบรรยายภาพสำหรับผู้พิการทางการมองเห็น <br />
                            เพียงผู้พิการทางสายตาโหลดแอพพลิเคชั่นดังกล่าว <br />
                            ก็สามารถเดินเข้าโรงภาพยนตร์<br />
                            และสามารถชมภาพยนตร์ด้วยการเสียบหูฟัง<br />
                            เพื่อฟังเสียงบรรยายภาพจากภาพยนตร์เรื่องนั้นๆ ได้อย่างง่ายดาย<br />
                            ถือเป็นแอพพลิเคชั่นชมภาพยนตร์สำหรับผู้พิการทางสายตาที่ถือกำเนิดขึ้นเป็นครั้งแรกของประเทศไทย
                        </p>
                        <br />

                        <p>
                            แอพพลิเคชั่นเสียงบรรยายภาพนี้<br />
                        จะช่วยให้ผู้พิการทางการมองเห็นสามารถสร้างภาพจินตนาการจากการฟังบรรยายภาพด้วยเสียง<br />
                        ทำให้รับชมเรื่องราวได้อย่างมีอรรถรส รองรับได้ทั้ง IOS และ Android
                        โดยมีรายการสารบัญของเสียงบรรยายภาพของภาพยนตร์ เมื่อเปิดดูภาพยนตร์
                        และเปิดใช้งานแอพพลิเคชั่นพรรณนาพร้อมกับเปิด AD (เสียงบรรยาย)
                        ของภาพยนตร์เรื่องนั้นไปพร้อมกัน
                        </p>
                        <br />
                        <p>
                        การทำงานของพรรณนาคือฟังเสียงของภาพยนตร์ที่ดำเนินอยู่ขณะนั้น 
                        เชื่อมต่อเข้ากับระบบฐานข้อมูล ค้นหาเสียงบรรยายภาพ 
                        และเริ่มใช้เสียงบรรยายภาพ ซึ่งเป็นการใช้งานที่ง่ายดายและสะดวกเป็นอย่างมาก 
                        แต่ก็ยังไม่ครอบคลุมสื่อทุกแขนง และมีความหลากหลายเพียงพอ 
                        ด้วยการพัฒนาของทีมงานด้วยกำลังคนเพียงกลุ่มเดียว 
                        </p>
                        <br/>
                        <p>
                        ดังนั้นทางทีม พรรณนา จึงได้ร่วมมือกับบริษัท bit.studio 
                        จัดทำเว็บไซต์นี้ขึ้นเพื่อให้ทุกคนสามารถเข้ามาช่วยเติมเต็ม AD 
                        (เสียงบรรยาย) โดยการเลือกคลิปวิดีโอที่สนใจ จากนั้นกดเลือกตามช่อง 
                        ที่เราแบ่งเอาไว้ให้ เพื่อพิมพ์อธิบาย “พรรณนา” เรื่องราว 
                        ในแบบที่คุณคิดว่าเหมาะสมแก่การบอกเล่า 
                        ให้ผู้พิการทางสายตาได้รับทราบเกี่ยวกับฉากนั้น ๆ จากนั้นก็บันทึกข้อมูล 
                        ข้อมูลจะถูกรวบรวม เพื่อให้ทางทีมงานสามารถนำไปคัดเลือก 
                        และนำไปพัฒนาต่อเป็นเสียงบรรยายจริง 
                        </p>
                        <br/>
                        <p>
                        มาร่วมเป็นส่วนหนึ่งในการช่วยกันสร้าง AD (เสียงบรรยาย) 
                        ให้แก่ผู้พิการทางสายตา เพื่อให้ทุก ๆ คนสามารถรับสารได้อย่าง 
                        มีอรรถรสเท่าเทียมกัน
                        </p>
                    </Content>
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default About;
