export const createTimeFormat = (secs) => {
    let pad = function (num, size) { return ('000' + num).slice(size * -1); }

    let time = parseFloat(secs).toFixed(3)

    let hours = Math.floor(time / 60 / 60)

    let hoursPad = pad(hours, 2)

    let minutes = Math.floor(time / 60) % 60
    let minutesPad = pad(minutes, 2)

    let seconds = Math.floor(time - minutes * 60)
    let secondsPad = pad(seconds, 2)
    

    return [` ${Number(hours) > 0 ? `${hoursPad}:` : ``}${minutesPad}:${secondsPad}`, parseInt(hours), parseInt(minutes), parseInt(secondsPad)]
}

const thaiMonthAbbreviated = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค."
]

function pad(number) {
    let res = number.toString().padStart(2, '0')

    return res
}

export const formatTimeStamp = (datetime) => {
    let date = datetime.getDate()
    date = pad(date)

    let month = datetime.getMonth()
    month = thaiMonthAbbreviated[month]
    month = pad(month)

    let year = datetime.getFullYear()
    year = year + 543

    let hour = datetime.getHours()
    hour = pad(hour)

    let minute = datetime.getMinutes()
    minute = pad(minute)

    return `${date} ${month} ${year}, ${hour}:${minute}น.`
}

export const getYouTubeID = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

export const scrollToSubtitleSlot = (elemId) => {
    return new Promise((resolve) => {
        const subtitleElem = document.getElementById(elemId)
        subtitleElem.scrollIntoView({ behavior: "smooth" })
        resolve()
    })
}