import React, { useState } from 'react'
import { Input as InputBase } from 'semantic-ui-react'
import styled from 'styled-components'
import Button from '../../Button'
import Icon from '../../Icon'
import ConfirmModal from '../ConfirmModal'
import colors from '../../../../styles/global.scss'
import { useRecoilState } from 'recoil'
import { Role, IsLogin, userType } from '../../../../recoil/atoms'
import { ROLES } from '../../../../constants/authenticate'

const Modal = styled(ConfirmModal)`
    &.ui.modal {
        width: 684px;

        .header {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;

            svg {
                width: 48px;
            }
        }

        .content {
            margin: 48px 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-family: "Prompt-Light";
        }

        .actions {
            .ui.button {
                width: 108px;
            }
        }
    }
`

const TriggerButton = styled.span`
    cursor: pointer;
    color: white;
    padding: 3px 6px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.lightGrey};
    border-radius: 8px;

    font-size: 12px;
    font-family: "Prompt-Medium";

    &:hover {
        color: ${colors.primary};
    }

    &:hover path {
        fill: ${colors.primary};
    }
`

const IconContainer = styled.span`
    height: 16px;
    width: 32px;

    & svg {
        height: 100%;
    }
`

function Login(props) {
    const [_, setRole] = useRecoilState(Role);
    const [isLogin, setIsLogin] = useRecoilState(IsLogin)

    const [open, setOpen] = useState(false)
    function handleTriggerClick() {
        setOpen(previousState => !previousState)
    }

    async function handleLogout() {
        setRole(ROLES.USER)
        setIsLogin(false)
        localStorage.setItem("ROLE", JSON.stringify(ROLES.USER))
        localStorage.setItem("LOGIN_STATE", false)
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            trigger={
                <TriggerButton onClick={handleTriggerClick}>
                    <IconContainer>
                        <Icon name="exit" color="white" />
                    </IconContainer>
                    ออกจากระบบ
                </TriggerButton>}
        >
            <Modal.Header>
                <Icon name="exit" color={colors.primary} />
                <span>
                    ออกจากระบบแอดมิน
                </span>
            </Modal.Header>
            <Modal.Content>
                คุณต้องการออกจากระบบหรือไม่
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={handleTriggerClick}>
                    ยกเลิก
                </Button>
                <Button positive onClick={handleLogout}>
                    ออกจากระบบ
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default Login