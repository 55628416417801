import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import * as Utils from '../../../utils/utils'
import { TextArea as TextAreaBase } from '../TextArea'
import PopupMenu from '../PopupMenu'
import Button from "../../CustomComponents/Button"
import { AttributeItem as AttributeItemBase } from '../AttributeItem'
import {
    Card as CardBase,
    CardMeta as CardMetaBase,
    CardTitle as CardTitleBase,
    CardContent as CardContentBase,
    CardFooter as CardFooterBase
} from '../Card'

import Icon from '../Icon'
import { TagInventory } from '../../../constants/tag-inventory'
import Tag from '../../CustomComponents/Tag'
import { motion } from 'framer-motion'

import { useRecoilValue } from 'recoil'
import { isAdminSelector } from '../../../recoil/selectors'
import { Transition } from 'semantic-ui-react'

const CardWrapper = styled.div`
    padding:0;
    margin:0;
`

const Card = styled(CardBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                width: `360px`,
                height: `224px`,
                backgroundColor: colors.cardHover
            }
        })
    else {
        return ({
            style: {
                backgroundColor: props.hoverState ? colors.cardHover : colors.card,
                width: `360px`,
                height: `224px`,
            }
        })
    }
})``

const CardMeta = styled(CardMetaBase).attrs((props) => ({
    style: {
        paddingTop: `0.7500em`,
        paddingBottom: `0`
    }
}))``

const CardTitle = styled(CardTitleBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                color: colors.primary
            }
        })
    else {
        return ({
            style: {
                color: props.hoverState ? colors.primary : colors.white,
            }
        })
    }
})`
    -webkit-box-orient: unset;
    display: flex;
    flexDirection: row;
    justify-content: space-between;
`

const CardContent = styled(CardContentBase).attrs((props) => ({

}))`
    padding-top: 0px;
    padding-bottom: 0px;
`

const CardFooter = styled(CardFooterBase).attrs((props) => ({
    style: {
        position: `relative`,
        alignItems: `center`,
        paddingTop: `0`,
        paddingBottom: `0`,
        minHeight: `48px`
    }
}))``

const TextArea = styled(TextAreaBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                backgroundColor: `${colors.card}`,
                cursor: `pointer`,
                minWidth: `100%`,
                minHeight: `84px`,
                maxHeight: `84px`
            }
        })
    return ({
        style: {
            backgroundColor: props.hoverState ? colors.card : colors.grey,
            cursor: `pointer`,
            minWidth: `100%`,
            minHeight: `84px`,
            maxHeight: `84px`
        }
    })
})``

const TextAreaWrapper = (props) => {
    const handleOnTextAreaChange = (event, data) => {
        props.onTextChangeListener(data.value)
    }

    return (
        <TextArea
            selected={props.selected}
            hoverState={props.hoverState}
            value={props.text}
            onChange={(event, data) => handleOnTextAreaChange(event, data)}
        />
    )
}

const AttributeItem = styled(AttributeItemBase).attrs((props) => ({
    style: {
        flex: `1`
    }
}))``

const MenuButton = styled.div`
	{
		display: flex;
		flex: 1;
		justify-content: flex-end;
		text-align: end;
		align-self: flex-end;
	}
`

const AddSubtitle = styled(Card).attrs((props) => ({

}))`
		&.ui.card {
			display:flex;
			justify-content:center;
			align-items:center;
			
		}

		&.ui.card:hover {
			border-color: transparent;
		}
`


const FooterWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    left: 0;
`

const TagWrapper = ({ tag }) => {
    return (
        <Tag {...tag} >{tag.label}</Tag>
    )
}


const SubtitleCardMotion = ({ children }) => {
    return (
        <motion.div
            whileTap={{ scale: 0.9 }}
            style={{ padding: 0 }}>
            {children}
        </motion.div>
    )
}
export const AddSubtitleCard = (props) => {
    const [hoverState, setHoverState] = useState(colors.white)

    return (
        <div  {...props}>
            <SubtitleCardMotion >
                <AddSubtitle
                    onMouseOver={() => { setHoverState(colors.primary) }}
                    onMouseLeave={() => { setHoverState(colors.white) }}
                >
                    <Icon
                        name='add'
                        color={hoverState}
                    />
                </AddSubtitle>
            </SubtitleCardMotion>
        </div>
    )
}


const Timeline = (props) => {
    const Style = {
        width: `${props.progressPercentage * 100}%`,
        height: `2.48vh`,
        backgroundColor: colors.primary
    }
    return (
        <div style={Style}>
            {/* <svg style={{ position: `absolute`, top: 0, left: 0 }} width="100%" height="5px">
                <line x1="0" y1="5px" x2={`${props.progressPercentage * 100}%`} y2="5px" style={{ stroke: colors.primary, strokeWidth: `5` }} />
            </svg> */}
        </div>
    )
}

const SubtitleEditField = ({ onClick, ...props },) => {
    const
        {
            subtitle,
            currentTime,
            onClickSelectSubtitleCollection,
            onClickEditDurationTime,
            onSubtitleUpdated,
            ...rest

        } = props;

    const startTimeInSecs = props.subtitle['subtitle']['startTime']
    const endTimeInSecs = props.subtitle['subtitle']['endTime']

    const [startTime] = Utils.createTimeFormat(startTimeInSecs)
    const [endTime] = Utils.createTimeFormat(endTimeInSecs)

    const duration = endTimeInSecs - startTimeInSecs

    const isAdmin = useRecoilValue(isAdminSelector)
    const [hoverState, setHoverState] = useState(false)
    const [onTextChange, setOnTextChange] = useState(false)

    const originalTextDescription = subtitle['subtitle']['text'] || ""
    const [textDescription, setTextDescription] = useState(subtitle['subtitle']['text'] || "")

    const [progressPercentage, setProgressPercentage] = useState(0)

    useEffect(() => {
        setTextDescription(subtitle['subtitle']['text'])
    }, [subtitle])


    useEffect(() => {
        let timeRemaining = currentTime >= startTimeInSecs ? Math.abs(startTimeInSecs - currentTime) : 0
        setProgressPercentage((timeRemaining / duration) >= 1.0 ? 1.0 : timeRemaining / duration)
    }, [currentTime])

    const onTextChangeListener = (text) => {
        setOnTextChange(true)
        setTextDescription(text)
    }

    const onSave = () => {
        setOnTextChange(false)
        subtitle['subtitle']['text'] = textDescription
        onSubtitleUpdated(props.subtitle)
    }

    const onCancel = () => {
        setTextDescription(originalTextDescription)
        setOnTextChange(false)
    }

    return (
        <CardWrapper onClick={onClick}>
            <Card {...rest}
                onMouseOver={() => { setHoverState(true) }}
                onMouseLeave={() => { setHoverState(false) }}
                hoverState={hoverState}
            >
                <Timeline
                    progressPercentage={progressPercentage}
                />
                <CardMeta tags>
                    {isAdmin &&
                        <> {subtitle['subtitle']['approved'] && <TagWrapper tag={TagInventory("APPROVED")} />}</>
                    }
                    <MenuButton >
                        {
                            props.menuitems &&
                            <PopupMenu menuitems={props.menuitems} data={subtitle} />
                        }
                    </MenuButton>
                </CardMeta>
                <CardTitle hoverState={hoverState} {...rest}>
                    <div>ช่องที่ {subtitle['index'] + 1} / {props.length}  </div>
                    <div style={{ fontFamily: `Sarabun-Light`, fontSize: `0.9rem`, color: colors.lightGrey }}>{`คำบรรยายไม่ควรเกิน ${Math.ceil(duration * 4)} คำ`}</div>

                </CardTitle>
                <CardContent >
                    <TextAreaWrapper
                        selected={props.selected}
                        hoverState={hoverState}
                        text={textDescription}
                        onTextChangeListener={(text) => onTextChangeListener(text)}
                    />
                </CardContent>
                <CardFooter>
                    <Transition.Group
                        animation={`slide up`}
                        duration={500}
                    >
                        {!onTextChange &&
                            (
                                <FooterWrapper>
                                    <div style={{ display: `flex`, }}>
                                        <AttributeItem onClick={(e) => { isAdmin ? onClickEditDurationTime(e, subtitle) : null }} icon="clock" color="white" value={`${startTime} - ${endTime}`} />
                                        {
                                            isAdmin &&
                                            < AttributeItem onClick={(e) => { onClickSelectSubtitleCollection(e, subtitle) }} icon="checkList" color="white" value={`คำบรรยาย ${subtitle['subtitle']['subtitleCount'] ? subtitle['subtitle']['subtitleCount'] : 0} ตัวเลือก`} />
                                        }
                                    </div>
                                </FooterWrapper>
                            )
                        }
                        {onTextChange &&
                            (
                                <FooterWrapper>
                                    <div style={
                                        {
                                            display: `flex`,
                                            height: `100%`,
                                            justifyContent: `flex-end`,
                                            alignItems: `center`,
                                            paddingLeft: `1.5em`,
                                            paddingRight: `1.5em`
                                        }
                                    }>
                                        <Button
                                            negative
                                            style={{
                                                width: `64px`,
                                                height: `28px`,
                                                borderRadius: `8px`,
                                                padding: `0`,
                                                fontSize: `1.2em`,
                                                marginRight: `0.65em`
                                            }}
                                            onClick={() => onCancel()}
                                        >
                                            ยกเลิก
                                            </Button>
                                        <Button
                                            positive
                                            style={{
                                                width: `64px`,
                                                height: `28px`,
                                                borderRadius: `8px`,
                                                padding: `0`,
                                                fontSize: `1.2em`
                                            }}
                                            onClick={() => onSave()}
                                        >
                                            บันทึก
                                            </Button>
                                    </div>
                                </FooterWrapper>
                            )
                        }
                    </Transition.Group>
                </CardFooter>
            </Card>
        </CardWrapper>
    )
}

export default SubtitleEditField