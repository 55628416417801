import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router';
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import DeleteVideo from '../../CustomComponents/ConfirmedPage/DeleteVideo'
import Videos from '../VideoPage/Videos'
import { VIDEO } from '../../../constants/routes'
import { FirebaseContext } from '../../Firebase'

import { useRecoilState } from 'recoil'
import { NavSections } from '../../../recoil/atoms'

const Container = styled.div`
    {
        width: 100%;
    }
`

const SelectionPageBase = (props) => {
    const [, setNavSelection] = useRecoilState(NavSections)

    const [openDeleteVideo, setOpenDeleteVideo] = useState(false)
    const [video, setVideo] = useState(null)

    useEffect(() => {
        setNavSelection((oldSelection) => [
            { key: 'selection', content: 'วิดีโอ', link: true }
        ])
    }, [])


    const handleDeleteVideo = async (videoData) => {
        let video = videoData
        setVideo(video)
        await props.firebase.deleteVideo(video);
        await props.firebase.deleteSubtitleCollection(video)
        setVideo(null)
    }

    const onClickVideoListener = async (videoData) => {
        let video = videoData
        setVideo(video)
        navigate(`${VIDEO}/${video.id}`, { state: { video: video } })

    }

    const onClickVideoSelectedListener = async (e, videoData) => {
        let video = videoData
        setVideo(video)
        navigate(`${VIDEO}/${video.id}`, { state: { video: video } })
    }

    const menuitems = [
        {
            icon: 'selectSlot',
            color: colors.white,
            hoverColor: colors.primary,
            text: 'เลือกวิดีโอ',
            onClick: onClickVideoSelectedListener
        }
    ]

    return (
        <>
            {
                !video &&
                <Container>
                    <Videos
                        firebase={props.firebase}
                        onClickVideoListener={(video) => onClickVideoListener(video)}
                        menuitems={menuitems}
                    />
                </Container>
            }
            {
                /** Handle dialog rendering **/
                video &&
                <DeleteVideo
                    onClose={() => setOpenDeleteVideo(false)}
                    onOpen={() => setOpenDeleteVideo(true)}
                    open={openDeleteVideo}
                    videoCardProps={video}
                    handleDeleteVideo={() => handleDeleteVideo(video)}
                />
            }

        </>
    )
}

const SelectionPage = (props) => {
    return (
        <SelectionPageBase {...props} />
    )
}

export default (props) => (
    <FirebaseContext.Consumer>
        {(value) => (
            <SelectionPage {...props} firebase={value} />
        )}
    </FirebaseContext.Consumer>
)