import React from 'react'
import styled from 'styled-components';
import Button from '../../Button';
import ConfirmModal from '..';

const Modal = styled(ConfirmModal)`
    &.ui.modal {
        width: 444px;
        height: 194px;
         
        .ui.button {
                width: 124px;
                height: 36px;
            }
    }
`

const Header = {
    Top: styled.div`
        font-size: 20px;
    `,
    Bottom: styled.div`
        font-size: 20px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 36px;
        margin-bottom: 48px;
    `
}

function Disapprove(props) {

    const content = {
        header: {
            top: "ต้องการยกเลิกการยืนยัน",
            bottom: "การตรวจสอบวิดีโอนี้"
        },
        buttons: {
            negative: {
                text: "ยกเลิก",
            },
            positive: {
                text: "ยกเลิกการยืนยัน"
            }
        }
    };

    return (
        <Modal {...props}>
            <Modal.Header>
                <Header.Top>{content.header.top}</Header.Top>
                <Header.Bottom>{content.header.bottom}</Header.Bottom>
            </Modal.Header>
            <Modal.Actions>
                <Button negative onClick={() => { props.onClose() }} >
                    {content.buttons.negative.text}
                </Button>
                <Button positive onClick={() => { props.handleDisapproveVideo() }}>
                    {content.buttons.positive.text}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default Disapprove;