import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player/youtube"
import { FirebaseContext } from "../../../../components/Firebase"
import styled from "styled-components"
import colors from "../../../../styles/global.scss"
import { Grid, Container } from "semantic-ui-react"
import SubtitleEditField, {
} from "../../../CustomComponents/SubtitleEditField"

import Button from "../../../CustomComponents/Button"
import Icon from "../../../CustomComponents/Icon"

import { useRecoilValue, useRecoilState } from "recoil"
import { uniqueIdSelector } from "../../../../recoil/selectors"

import { NavSections } from "../../../../recoil/atoms"

import Loader from "../../../CustomComponents/Loader/Loader"
import { navigate } from "gatsby"
import { HOME } from "../../../../constants/routes"

import { scrollToSubtitleSlot } from "../../../../utils/utils"

const TitleWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 2rem;
`

const Title = styled.div`
    align-self: center;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white}; 
`

const ToggleLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white}; 
`


const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
`

const ReactPlayerWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
`

const AboutVideo = styled.div`
    padding-top: 2rem;
    padding-bottom: rem;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white};
`

const AboutDescription = styled.div`
    width: 100%;
    height: 6vh;
    overflow-y: auto;
    font-family: "Prompt-medium";
    font-size: 1rem;
    color: ${colors.white};
    &::-webkit-scrollbar {
        display: none;
    }
`


const ButtonWrapper = styled.div`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 5.2vh;
    button {
        height: 45px;
        width: 200px;
    }
`

const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content:center;
    svg {
        min-width: 20px;
        min-height: 20px;
        max-width: 32px;
        max-height: 32px;
    }

    label {
        padding-left: 5px;
        padding-right: 5px;
        width:100%;
        cursor: pointer;  
    }
`
const GridStyle = {
    marginLeft: 0,
    marginRight: 0
}

const LayoutToggleButtonWrapper = styled.div`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
           
`
const ButtonStyle = {
    height: `45px`,
    maxWidth: `160px`,
    padding: `0`
}

const LayoutToggleButton = (props) => {
    const LabelStyle = {
        paddingLeft: `5px`,
        paddingRight: `5px`,
        width: `100%`,
        cursor: `pointer`,
        fontWeight: `${props.active ? `500` : 'normal'}`,
        color: `${props.active ? colors.primary : colors.lightGrey}`
    }

    return (
        <LayoutToggleButtonWrapper onClick={props.onClick}>
            <Button style={ButtonStyle} color={colors.delete} >
                <ButtonContent>
                    <Icon name={props.icon} color={props.active ? colors.primary : colors.lightGrey} />
                    <label style={LabelStyle}> {props.label || ""} </label>
                </ButtonContent>
            </Button >
        </LayoutToggleButtonWrapper>
    )
}

const Video = props => {
    const uniqueId = useRecoilValue(uniqueIdSelector)

    const videoRef = useRef({})
    // const isAdmin = useRecoilValue(isAdminSelector)
    const [subtitle, setSubtitle] = useState(null)
    const [activeLoader, setActiveLoader] = useState(false)

    const [firstLoad, setFirstLoad] = useState(false)
    const [activeSubtitle, setActiveSubtitle] = useState(null)

    const [video, setVideo] = useState(props.video)
    const [subtitles, setSubtitles] = useState(props.subtitles)

    const [playVideo, setPlayVideo] = useState(false)
    const [videoColumn, setVideoColumn] = useState(4)
    const [segmentColumn, setSegmentColumn] = useState(12)
    const [videoPlayerSize, setVideoPlayerSize] = useState({ width: 100, height: 100 })

    const [hasVideoExpanded, setHasVideoExpanded] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)

    useEffect(() => {
        setVideo(props.video)
    }, [{ ...props.video }])

    useEffect(() => {
        setSubtitles(props.subtitles)
    }, [{ ...props.subtitles }])

    useEffect(() => {
        if (hasVideoExpanded) {
            setVideoColumn(11)
            setSegmentColumn(5)
            setVideoPlayerSize({ width: 860, height: 860 * 9 / 16 })
        } else {
            setVideoColumn(4)
            setSegmentColumn(12)
            setVideoPlayerSize({ width: 380, height: 380 * 9 / 16 })
        }
    }, [hasVideoExpanded])

    const onSubtitleUpdated = async (subtitle) => {
        // if (subtitleData["subtitle"]["text"] === "") return
        setActiveLoader(true)
        await props.firebase.createSubtitleCollection(video, subtitle["subtitle"], uniqueId)
        props.forceUpdate()
        setActiveLoader(false)
    }

    const setPlayHead = (time = 0) => {
        const videoPlayer = videoRef.current
        const duration = videoPlayer.getDuration()
        videoPlayer.seekTo(time / duration)
        setCurrentTime(time)
    }

    const onProgress = () => {
        if (firstLoad) {
            if (playVideo) {
                const videoPlayer = videoRef.current
                setCurrentTime(videoPlayer.getCurrentTime())
                for (var i = 0; i < subtitles.length; i++) {
                    if (
                        currentTime >= subtitles[i]["subtitle"]["startTime"] &&
                        currentTime <= subtitles[i]["subtitle"]["endTime"]
                    ) {
                        setActiveSubtitle(subtitles[i]["subtitle"]["id"])
                        scrollToSubtitleSlot(subtitles[i]["subtitle"]["id"])
                    }
                }
            }
        }
        setFirstLoad(true)
    }

    const onClickSubtitleEditField = (e, subtitle) => {
        e.stopPropagation()
        selectedSubtitleSlot(subtitle)
    }

    const onClickSelectSubtitle = (e, subtitle) => {
        e.stopPropagation()
        selectedSubtitleSlot(subtitle)
    }

    const selectedSubtitleSlot = (subtitle) => {
        setPlayVideo(false)
        setActiveSubtitle(subtitle["subtitle"]["id"])
        setPlayHead(subtitle["subtitle"]["startTime"])
        scrollToSubtitleSlot(subtitle["subtitle"]["id"])
    }

    const menuitems = [
        {
            icon: "selectSlot",
            color: colors.white,
            hoverColor: colors.primary,
            text: "เลือกช่องเสียงบรรยายนี้",
            onClick: onClickSelectSubtitle,
        },
    ]

    return (
        <Container fluid style={{ paddingLeft: '6%' }} >
            {video && (
                <>
                    <TitleWrapper>
                        <Title> {video.title} </Title>
                        <ToggleLayout>
                            <LayoutToggleButton
                                onClick={() => { setTimeout(() => { setHasVideoExpanded(false) }, 100); }}
                                label={`วิดีโอขนาดเล็ก`}
                                icon={`smallLayout`}
                                active={!hasVideoExpanded}

                            />
                            <LayoutToggleButton
                                onClick={() => { setTimeout(() => { }, 100); setHasVideoExpanded(true) }}
                                label={`วิดีโอขนาดใหญ่`}
                                icon={`largeLayout`}
                                active={hasVideoExpanded}
                            />
                        </ToggleLayout>
                    </TitleWrapper>
                    <ContentWrapper>
                        <Grid stackable style={GridStyle}>
                            <Grid.Column
                                width={videoColumn}
                                style={{
                                    display: `flex`,
                                    flexDirection: `column`,
                                    flex: `1`,
                                    height: `82.8vh`,
                                    overflowY: `auto`,
                                }}
                            >
                                <Container
                                    style={
                                        {
                                            display: `flex`,
                                            flexDirection: `column`,
                                            height: `100%`,
                                            width: `${videoPlayerSize['width']}px`
                                        }
                                    }>
                                    <div
                                        style={{
                                            width: `100%`,
                                            height: `${videoPlayerSize['width'] * 0.5625}px`
                                        }}
                                    >
                                        <ReactPlayer
                                            width="100%"
                                            height="100%"
                                            ref={videoRef}
                                            url={video.url}
                                            playing={playVideo}
                                            controls={true}
                                            progressInterval={0}
                                            onProgress={() => onProgress()}
                                            onPlay={() => { setPlayVideo(true) }}

                                        />
                                    </div>

                                    <AboutVideo>
                                        <p>คำอธิบายวิดีโอ </p>
                                    </AboutVideo>

                                    <AboutDescription>
                                        {video.description}
                                    </AboutDescription>
                                </Container>
                            </Grid.Column>

                            <Grid.Column
                                width={segmentColumn}
                                textAlign={`center`}
                                style={{ height: `82.8vh`, overflowY: `auto` }}
                            >
                                <Grid style={GridStyle}>
                                    <Grid.Row style={{ padding: 0 }}>

                                        {/* {isAdmin && <AddSubtitleCard onClick={(e) => onClickAddSubtitle()} />} */}
                                        {subtitles &&
                                            subtitles.map((subtitle, index) => {
                                                return (
                                                    <SubtitleEditField
                                                        id={subtitle["subtitle"]["id"]}
                                                        key={index}
                                                        placeholder=""
                                                        subtitle={subtitle}
                                                        length={subtitles.length}
                                                        menuitems={menuitems}
                                                        selected={activeSubtitle === subtitle["subtitle"]["id"] ? true : false}
                                                        onClick={e => onClickSubtitleEditField(e, subtitle, index)}
                                                        onSubtitleUpdated={(subtitle) => onSubtitleUpdated(subtitle)}
                                                        currentTime={currentTime}
                                                    />
                                                )
                                            })}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </ContentWrapper>
                </>
            )}

            {activeLoader && <Loader />}
        </Container>
    )
}

const VideoPageBase = props => {
    const uniqueId = useRecoilValue(uniqueIdSelector)
    const [_, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const [activeLoader, setActiveLoader] = useState(false)

    const [, setNavSelection] = useRecoilState(NavSections)
    const [openSubtitleEditor, setOpenSubtitleEditor] = useState(false)
    const [video, setVideo] = useState(null)
    const [subtitle, setSubtitle] = useState(null)
    const [subtitles, setSubtitles] = useState(null)

    const [hasFetched, setHasFetched] = useState(false)

    useEffect(() => {
        if (!props.firebase) return
        const unsubscribe = props.firebase
            .getVideoRefById(props.videoId)
            .onSnapshot(snapshot => {
                console.log("doc changes", snapshot)
                const data = snapshot.data()
                const removed = !data
                if (removed) {
                    navigate(HOME, { replace: true })
                } else {
                    const published = data.published
                    if (!published) {
                        navigate(HOME, { replace: true })
                    }
                }
            })
        return () => unsubscribe()
    }, [props.firebase, props.videoId])

    const onSelectSubtitleListener = subtitle => {
        setOpenSubtitleEditor(true)
        setSubtitle(subtitle)
    }

    useEffect(() => {
        if (props.firebase) {
            let videoTemp = ""
            let subtitlesTemp = []
            const fetchData = async () => {
                setActiveLoader(true)
                await fetchVideo()
                await fetchSubtitles()
                setHasFetched(true)
                setNavigateSelection()
                setActiveLoader(false)
            }

            const fetchVideo = async () => {
                return new Promise((resolve) => {
                    props.firebase.getVideoRefById(props.videoId)
                        .onSnapshot(snapshot => {
                            videoTemp = snapshot.data()
                            videoTemp["id"] = props.videoId
                            setVideo(videoTemp)
                            resolve()                            
                        })
                })
            }

            const fetchSubtitles = async () => {
                subtitlesTemp = await props.firebase.getSubtitlesByVideoId(
                    props.videoId
                )
                subtitlesTemp = subtitlesTemp.map((subtitle, index) => {
                    subtitle["text"] = ""
                    return { index: index, subtitle: subtitle }
                })

                var i = 0
                for (const subtitleObj of subtitlesTemp) {
                    let subtitleCollection = await props.firebase.getSubtitleFromCollectionById(
                        videoTemp,
                        subtitleObj["subtitle"],
                        uniqueId
                    )
                    if (subtitleCollection) {
                        subtitlesTemp[i]["subtitle"]["text"] =
                            subtitleCollection["text"]
                    }
                    i++
                }

                setSubtitles(subtitlesTemp)
            }
            const setNavigateSelection = () => {
                setNavSelection(oldSelection => [
                    {
                        key: "selection",
                        content: "วิดีโอ",
                        href: `/`,
                    },
                    {
                        key: "video",
                        content: `${videoTemp.title}  (ช่องเสียงทั้งหมด)`,
                        href: `/video/${props.videoId}`,
                    },
                ])
            }
            fetchData()
        }
    }, [props.firebase])

    return (
        <>
            {hasFetched && (
                <>
                    <Video
                        {...props}
                        video={video}
                        subtitles={subtitles}
                        onSelectSubtitleListener={onSelectSubtitleListener}
                        forceUpdate={forceUpdate}
                    />
                </>
            )}
            {activeLoader && <Loader />}
        </>
    )
}

const VideoPage = props => {
    return (
        <FirebaseContext.Consumer>
            {value => <VideoPageBase {...props} firebase={value} />}
        </FirebaseContext.Consumer>
    )
}

export default VideoPage
