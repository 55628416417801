import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router';
import axios from 'axios'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import DeleteVideo from '../../CustomComponents/ConfirmedPage/DeleteVideo'
import Videos from '../VideoPage/Videos'
import { VIDEO } from '../../../constants/routes'
import { FirebaseContext } from '../../Firebase'
import Loader from '../../CustomComponents/Loader/Loader'

import { useRecoilState } from 'recoil'
import { NavSections } from '../../../recoil/atoms'


const Container = styled.div`
    {
        width: 100%;
    }
`

const SelectionPageBase = (props) => {
    const [, setNavSelection] = useRecoilState(NavSections)

    const [video, setVideo] = useState(null)
    const [openDeleteVideo, setOpenDeleteVideo] = useState(false)
    const [activeLoader, setActiveLoader] = useState(false)

    useEffect(() => {
        setNavSelection((oldSelection) => [
            { key: 'selection', content: 'วิดีโอ', link: true }
        ])
    }, [])

    const handleDeleteVideo = async (videoData) => {
        let video = videoData
        setVideo(video)
        setActiveLoader(true)
        await props.firebase.deleteVideo(video)
        await props.firebase.deleteSubtitleCollection(video)
        setOpenDeleteVideo(false)
        setActiveLoader(false)
    }

    const onClickVideoListener = async (videoData) => {
        let video = videoData
        setVideo(video)
        navigate(`/admin${VIDEO}/${video.id}`, { state: { video: video } })
    }

    const onClickVideoSelectedListener = async (e, videoData) => {
        let video = videoData
        setVideo(video)
        navigate(`/admin${VIDEO}/${video.id}`, { state: { video: video } })
    }

    const onClickVideoSharedListener = async (e, videoData) => {
        setActiveLoader(true)
        await axios.post('https://text-to-speech-sa25i4aekq-df.a.run.app/text_to_speech', { id: videoData.id })
        setActiveLoader(false)
        window.open(`https://storage.googleapis.com/pannana-ml-storage/speech_generation/${videoData.id}.wav`)
    }

    const onClickVideoDeletedListener = async (e, videoData) => {
        let video = videoData
        setVideo(video)
        setOpenDeleteVideo(true)
    }

    const menuitems = [
		{
			icon: 'selectSlot',
			color: colors.white,
			hoverColor: colors.primary,
			text: 'เลือกวิดีโอ',
			onClick: onClickVideoSelectedListener
		},
		{
            type: 'sharing',
			icon: 'upload',
			color: colors.white,
			hoverColor: colors.primary,
			text: 'สร้างไฟล์เสียง',
			onClick: onClickVideoSharedListener

		},
		{
			icon: 'trashCan',
			color: colors.delete,
			hoverColor: colors.delete,
			text: 'ลบวิดีโอ',
			onClick: onClickVideoDeletedListener
		}
	]

    return (
        <>
            <Container>
                <Videos
                    firebase={props.firebase}
                    onClickVideoListener={(video) => onClickVideoListener(video)}
                    menuitems={menuitems}                 
                />
            </Container>


            { /** Handle dialog rendering **/}

            <DeleteVideo
                onClose={() => setOpenDeleteVideo(false)}
                onOpen={() => setOpenDeleteVideo(true)}
                open={openDeleteVideo}
                videoCardProps={video}
                handleDeleteVideo={() => handleDeleteVideo(video)}
            />


            { activeLoader && <Loader />}

        </>
    )
}

const SelectionPage = (props) => {
    return (
        <SelectionPageBase {...props} />
    )
}

export default (props) => (
    <FirebaseContext.Consumer>
        {(value) => (
            <SelectionPage {...props} firebase={value} />
        )}
    </FirebaseContext.Consumer>
)