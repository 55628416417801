import colors from '../styles/global.scss'

export const TagInventory = (tag) => {
    switch (tag) {
        case "APPROVED":
            return { color: colors.approved, label: "ตรวจสอบแล้ว" }
        case "PUBLISH":
            return { color: colors.primary, label: "กำลังเผยแพร่" }
        case "UNPUBLISH":
            return { color: colors.white, label: "ยังไม่เผยแพร่" }
        case "SUBMITTED":
            return { color: colors.approved, label: "ยืนยันข้อความแล้ว" }
        default:
            break;
    }
}