import React, { useState, useEffect, useRef } from "react"
import axios from 'axios'
import { navigate } from "@reach/router"
import { HOME, VIDEO } from "../../../../constants/routes"
import ReactPlayer from "react-player/youtube"
import { FirebaseContext } from "../../../../components/Firebase"
import styled from "styled-components"
import colors from "../../../../styles/global.scss"
import { Grid, Form, Container } from "semantic-ui-react"
import SubtitleEditField, {
    AddSubtitleCard,
} from "../../../CustomComponents/SubtitleEditField"

import { TagInventory } from "../../../../constants/tag-inventory"
import Button from "../../../CustomComponents/Button"
import Icon from "../../../CustomComponents/Icon"
import Radio from "../../../CustomComponents/Radio"
import Tag from "../../../CustomComponents/Tag"
import PopupMenu from "../../../CustomComponents/PopupMenu"

import AddSegment from "../../../CustomComponents/ConfirmedPage/AddSegment"
import ChangeStatus from "../../../CustomComponents/ConfirmedPage/ChangeStatus"
import DeleteVideo from "../../../CustomComponents/ConfirmedPage/DeleteVideo"
import DeleteSubtitle from "../../../CustomComponents/ConfirmedPage/DeleteSubtitle"
import Disapprove from "../../../CustomComponents/ConfirmedPage/Disapprove"
import CreateVideoCard from "../../../CustomComponents/CreateVideoCard"

import SubtitleEditorPage from "../../SubtitleEditorPage"
import { createTimeFormat, scrollToSubtitleSlot } from "../../../../utils/utils"

import { isAdminSelector } from "../../../../recoil/selectors"
import { useRecoilValue, useRecoilState } from "recoil"
import { NavSections } from "../../../../recoil/atoms"

import Loader from "../../../CustomComponents/Loader/Loader"

import useWindowResize from "../../../../useEffects/useWindowResize"
import checkOverlapOfRange from "../../../Utils/checkOverlapOfRange"
import { Parser } from "json2csv"

const TitleWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 2rem;
`

const Title = styled.div`
    align-self: center;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white}; 
`

const ToggleLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white}; 
`

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    // padding-top: 0.5rem;
    // padding-bottom: 0.5rem;
`

const AboutVideo = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: "Prompt-medium";
    font-size: 1.4rem;
    color: ${colors.white};
`

const AboutDescription = styled.div`
    width: 100%;
    height: 6vh;
    overflow-y: auto;
    font-family: "Prompt-medium";
    font-size: 1rem;
    color: ${colors.white};
`

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
    padding-bottom: 0rem;
`

const StatusHeader = styled.div`
    display: flex;
    flex-direction: row;
    font-family: "Prompt-medium";
    font-size: 1.25rem;
    color: ${colors.white};
    padding-top: 1rem;
    padding-bottom: 1rem;
`

const StatusTag = styled.div`
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
`

const StatusSelection = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Prompt-medium";
    font-size: 1.5rem;
    color: ${colors.white};
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    padding-left: 1rem;
    padding-right: 1rem;
`

const ButtonSelection = styled.div.attrs((props) => {
    return ({
        style: {
            display: `flex`,
            flexDirection: props.hasVideoExpanded ? `row` : `column`,
            flex: `1`
        }
    })
})``

const ButtonWrapper = styled.div`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 5.2vh;
    button {
        height: 45px;
        width: 200px;
    }
`

const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content:center;
    svg {
        min-width: 20px;
        min-height: 20px;
        max-width: 32px;
        max-height: 32px;
    }

    label {
        padding-left: 5px;
        padding-right: 5px;
        width:100%;
        cursor: pointer;  
    }
`

const GridStyle = {
    marginLeft: 0,
    marginRight: 0
}

const TagWrapper = ({ tag }) => {
    return <Tag {...tag}>{tag.label}</Tag>
}

const LayoutToggleButtonWrapper = styled.div`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
           
`
const ButtonStyle = {
    height: `45px`,
    maxWidth: `160px`,
    padding: `0`
}

const LayoutToggleButton = (props) => {
    const LabelStyle = {
        paddingLeft: `5px`,
        paddingRight: `5px`,
        width: `100%`,
        cursor: `pointer`,
        fontWeight: `${props.active ? `500` : 'normal'}`,
        color: `${props.active ? colors.primary : colors.lightGrey}`
    }

    return (
        <LayoutToggleButtonWrapper onClick={props.onClick}>
            <Button style={ButtonStyle} color={colors.delete} >
                <ButtonContent>
                    <Icon name={props.icon} color={props.active ? colors.primary : colors.lightGrey} />
                    <label style={LabelStyle}> {props.label || ""} </label>
                </ButtonContent>
            </Button >
        </LayoutToggleButtonWrapper>
    )
}

const EditSegment = (props) => { return (<AddSegment {...props} />) }

const Video = props => {
    const videoRef = useRef({})
    const isAdmin = useRecoilValue(isAdminSelector)

    const [subtitle, setSubtitle] = useState(props.subtitle)
    const [firstLoad, setFirstLoad] = useState(false)
    const [activeSubtitle, setActiveSubtitle] = useState(null)

    const [publishStatus, setPublishStatus] = useState(false)
    const [lastPublishStatus, setLastPublishStatus] = useState(publishStatus)


    const [approveStatus, setApproveStatus] = useState(true)

    const [openAddSegment, setOpenAddSegment] = useState(false)
    const [openEditSegment, setOpenEditSegment] = useState(false)
    const [openChangeStatus, setOpenChangeStatus] = useState(false)
    const [openDeleteVideo, setOpenDeleteVideo] = useState(false)
    const [openDeleteSubtitle, setOpenDeleteSubtitle] = useState(false)
    const [openDisapprove, setOpenDisapprove] = useState(false)
    const [openSubtitleEditor, setOpenSubtitleEditor] = useState(false)
    const [activeLoader, setActiveLoader] = useState(false)
    const [openEditVideoInfo, setOpenEditVideoInfo] = useState(false)

    const [video, setVideo] = useState(props.video)
    const [subtitles, setSubtitles] = useState(props.subtitles)


    const [playVideo, setPlayVideo] = useState(false)
    const [videoColumn, setVideoColumn] = useState(4)
    const [segmentColumn, setSegmentColumn] = useState(12)
    const [videoPlayerSize, setVideoPlayerSize] = useState({ width: 100, height: 100 })
    const [hasVideoExpanded, setHasVideoExpanded] = useState(false)
    const [subtitlesCollection, setSubtitlesCollection] = useState(null)

    const [currentTime, setCurrentTime] = useState(0)

    const windowSize = useWindowResize()

    useEffect(() => {
        if (props.video) {
            let video = props.video
            setVideo(video)
            setPublishStatus(video["published"])
            setLastPublishStatus(video["published"])
        }

    }, [props.video, props.subtitles])


    useEffect(() => {

        const updateSubtitles = async () => {
            setSubtitles(props.subtitles)
            checkApprroveStatus()
        }

        const checkApprroveStatus = async () => {
            // props.subtitles.forEach(subtitle => {
            for (const subtitle of props.subtitles) {
                if (!subtitle['subtitle']['approved']) {
                    setApproveStatus(true);
                    await props.firebase.updateVideoStatus(props.video, { approved: false })
                    return
                }
                setApproveStatus(false)
            }
        }

        if (props.subtitles != null && props.video != null) {
            updateSubtitles()
        }
    }, [props.video, props.subtitles])


    useEffect(() => {
        if (hasVideoExpanded) {
            setVideoColumn(11)
            setSegmentColumn(5)
            setVideoPlayerSize({ width: 860, height: 860 * 9 / 16 })
        } else {
            setVideoColumn(4)
            setSegmentColumn(12)
            setVideoPlayerSize({ width: 380, height: 380 * 9 / 16 })
        }
    }, [hasVideoExpanded])



    const setPlayHead = (time = 0) => {
        const videoPlayer = videoRef.current
        const duration = videoPlayer.getDuration()
        videoPlayer.seekTo(time / duration)
        setCurrentTime(time)
    }

    const onProgress = () => {
        if (firstLoad) {
            if (playVideo) {
                const videoPlayer = videoRef.current
                setCurrentTime(videoPlayer.getCurrentTime())
                for (var i = 0; i < subtitles.length; i++) {
                    if (
                        currentTime >= subtitles[i]["subtitle"]["startTime"] &&
                        currentTime <= subtitles[i]["subtitle"]["endTime"]
                    ) {
                        setActiveSubtitle(subtitles[i]["subtitle"]["id"])
                        scrollToSubtitleSlot(subtitles[i]["subtitle"]["id"])
                    }
                }
            }
        }
        setFirstLoad(true)
    }

    const onUpdateVideo = async (data) => {
        setActiveLoader(true)
        await props.firebase.updateVideoById(video.id, data)
        setOpenEditVideoInfo(false)
        setActiveLoader(false)
    }

    const onSubtitleUpdated = async (subtitle) => {
        setActiveLoader(true)
        await props.firebase.updateSubtitle(video, subtitle["subtitle"])
        setOpenSubtitleEditor(false)
        setSubtitle({ ...subtitle })
        setActiveLoader(false)
    }

    const onDeleteSubtitleCollection = async (subtitle) => {
        setActiveLoader(true)
        await props.firebase.deleteSubtitleInCollection(video, subtitle)
        let subtitlesCollection = await props.firebase.getSubtitlesCollectionById(
            video,
            subtitle["subtitle"]
        )
        setSubtitlesCollection(subtitlesCollection)
        setActiveLoader(false)
    }

    const handleAddSubtitle = async (startTime, endTime) => {

        setActiveLoader(true)
        await props.firebase.createSubtitle(video, startTime, endTime)
        props.forceUpdate()
        setActiveLoader(false)
        setOpenAddSegment(false)
        // setPlayVideo(true)
    }

    const handleEditSubtitleTime = async (startTime, endTime, subtitle) => {
        setActiveLoader(true)
        await props.firebase.updateSubtitleById(video, subtitle['subtitle'], { startTime: startTime, endTime: endTime })
        props.forceUpdate()
        setActiveLoader(false)
        setOpenEditSegment(false)
        // setPlayVideo(true)
    }

    const handleChangeStatus = async () => {
        setActiveLoader(true)
        await props.firebase.updateVideoStatus(video, {
            published: publishStatus,
        })
        props.forceUpdate()
        setActiveLoader(false)
        setOpenChangeStatus(false)
    }

    const handleApproveVideo = async () => {
        setActiveLoader(true)
        await props.firebase.updateVideoStatus(video, { approved: true })
        props.forceUpdate()
        setActiveLoader(false)
    }

    const handleDisapproveVideo = async () => {
        setActiveLoader(true)
        await props.firebase.updateVideoStatus(video, { approved: false })
        props.forceUpdate()
        setOpenDisapprove(false)
        setActiveLoader(false)
    }

    const handleDeleteVideo = async () => {
        setActiveLoader(true)
        await props.firebase.deleteVideo(video)
        await props.firebase.deleteSubtitleCollection(video)
        navigate(`/admin${HOME}`, { replace: true })
    }

    const handleDeleteSubtitle = async () => {
        setActiveLoader(true)
        await props.firebase.deleteSubtitleById(video, subtitle["subtitle"])
        props.forceUpdate()
        setActiveLoader(false)
        setOpenDeleteSubtitle(false)
    }

    /* handle click events. */
    const onClickAddSubtitle = () => {
        setPlayVideo(false)
        setOpenAddSegment(true)
    }

    const onClickPublishStatusVideo = () => {
        setOpenChangeStatus(true)
    }

    const onClickApproveVideo = () => {
        video.approved ? setOpenDisapprove(true) : handleApproveVideo()
    }

    const onClickSubtitleEditField = (e, subtitle) => {
        e.stopPropagation()
        setPlayVideo(false)
        setActiveSubtitle(subtitle["subtitle"]["id"])
        setPlayHead(subtitle["subtitle"]["startTime"])
        scrollToSubtitleSlot(subtitle["subtitle"]["id"])
    }

    const onClickClearSubtitle = async (e, subtitleData) => {
        e.stopPropagation()
        setActiveLoader(true)
        subtitleData["subtitle"]["text"] = ""
        await props.firebase.updateSubtitle(video, subtitleData["subtitle"])
        props.forceUpdate()
        setActiveLoader(false)
    }

    const onClickSubmitSubtitle = async (e, subtitle) => {
        e.stopPropagation()
        setActiveLoader(true)
        await props.firebase.updateSubtitleStatus(video, subtitle["subtitle"], {
            approved: true,
        })
        setActiveLoader(false)
    }

    const onClickCreateTextToSpeech = async (e, videoData) => {
        setActiveLoader(true)
        await axios.post('https://text-to-speech-sa25i4aekq-df.a.run.app/text_to_speech', { id: video.id })
        setActiveLoader(false)
        window.open(`https://storage.googleapis.com/pannana-ml-storage/speech_generation/${video.id}.wav`)
    }

    const onClickExportADScript = async () => {
        const subtitleList = []
        for (const subtitle of props.subtitles) {
            subtitleList.push({
                start_time: createTimeFormat(subtitle.subtitle.startTime)[0],
                end_time: createTimeFormat(subtitle.subtitle.endTime)[0],
                description: subtitle.subtitle.text
            })
        }
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(subtitleList);

        let file = new File(["\uFEFF" + csv], `${props.video.title}.csv`, {
            type: 'text/csv;charset=utf-8;',
            lastModified: Date.now(),
        })
        let linkDownload = document.createElement("a");
        linkDownload.href = window.URL.createObjectURL(file);
        linkDownload.target = "_blank"
        linkDownload.download = `${props.video.title}.csv`;
        linkDownload.click();
    }

    const onClickCancelSubtitle = async (e, subtitle) => {
        e.stopPropagation()
        setActiveLoader(true)
        await props.firebase.updateSubtitleStatus(video, subtitle["subtitle"], {
            approved: false,
        })
        await props.forceUpdate()
        setActiveLoader(false)
    }

    const onClickRemoveSubtitle = (e, subtitle) => {
        e.stopPropagation()
        setSubtitle(subtitle)
        setOpenDeleteSubtitle(true)
        setPlayVideo(false)
    }

    const onClickRemoveVideo = () => {
        setOpenDeleteVideo(true)
    }

    const onClickSelectSubtitleCollection = async (e, subtitle) => {
        e.stopPropagation()
        setPlayVideo(false)
        setActiveLoader(true)
        let subtitlesCollection = await props.firebase.getSubtitlesCollectionById(
            video,
            subtitle["subtitle"]
        )
        setSubtitlesCollection(subtitlesCollection)
        setSubtitle({ ...subtitle })
        setActiveSubtitle(subtitle["subtitle"]["id"])
        setPlayVideo(false)
        setActiveLoader(false)
        setOpenSubtitleEditor(true)
    }

    const onClickEditDurationTime = async (e, subtitle) => {
        e.stopPropagation()
        setSubtitle({ ...subtitle })
        setActiveSubtitle(subtitle["subtitle"]["id"])
        setPlayVideo(false)
        setOpenEditSegment(true)
    }

    const onClickOpenEditVideoInfo = () => {
        setOpenEditVideoInfo(true)
    }

    const menuitems = [
        {
            icon: "expand",
            color: colors.white,
            hoverColor: colors.primary,
            text: "แสดงตัวเลือกที่มี",
            onClick: onClickSelectSubtitleCollection,
        },
        {
            type: "approved",
            icon: "check",
            color: colors.white,
            hoverColor: colors.primary,
            text: "ยืนยันคำบรรยาย",
            onClick: onClickSubmitSubtitle,
        },
        {
            type: "not_approved",
            icon: "block",
            color: colors.white,
            hoverColor: colors.primary,
            text: "ยกเลิกการยืนยัน",
            onClick: onClickCancelSubtitle,
        },
        {
            icon: "trashCan",
            color: colors.delete,
            hoverColor: colors.delete,
            text: "ลบช่องนี้",
            onClick: onClickRemoveSubtitle,
        },
    ]

    const videoPopupItems = [
        {
            icon: "selectSlot",
            color: colors.white,
            hoverColor: colors.primary,
            text: "แก้ไขข้อมูลวิดีโอ",
            onClick: onClickOpenEditVideoInfo,
        },

        {
            icon: "trashCan",
            color: colors.delete,
            hoverColor: colors.delete,
            text: "ลบวิดีโอ",
            onClick: onClickRemoveVideo,
        },
    ]


    return (
        <Container fluid style={{ paddingLeft: '6%' }}>
            {
                video && (
                    <>
                        <TitleWrapper>
                            <Title> {video.title} </Title>
                            <ToggleLayout>
                                <LayoutToggleButton
                                    onClick={() => {
                                        console.log("test")
                                        setHasVideoExpanded(false)
                                    }}
                                    label={`วิดีโอขนาดเล็ก`}
                                    icon={`smallLayout`}
                                    active={!hasVideoExpanded}

                                />
                                <LayoutToggleButton
                                    onClick={() => {
                                        setHasVideoExpanded(true)
                                    }}
                                    label={`วิดีโอขนาดใหญ่`}
                                    icon={`largeLayout`}
                                    active={hasVideoExpanded}
                                />
                            </ToggleLayout>
                        </TitleWrapper>
                        <ContentWrapper>
                            <Grid stackable style={GridStyle} >
                                <Grid.Column
                                    width={videoColumn}
                                    style={{
                                        display: `flex`,
                                        flexDirection: `column`,
                                        flex: `1`,
                                        height: `82.8vh`,
                                        overflowY: `auto`,
                                    }}
                                >
                                    <Container
                                        style={
                                            {
                                                display: `flex`,
                                                flexDirection: `column`,
                                                height: `100%`,
                                                width: `${videoPlayerSize['width']}px`
                                            }
                                        }>
                                        <div
                                            style={{
                                                width: `100%`,
                                                height: `${videoPlayerSize['width'] * 0.5625}px`
                                            }}
                                        >
                                            <ReactPlayer
                                                width="100%"
                                                height="100%"
                                                ref={videoRef}
                                                url={video.url}
                                                playing={playVideo}
                                                controls={true}
                                                progressInterval={0}
                                                onProgress={() => onProgress()}
                                                onPlay={() => { setPlayVideo(true) }}

                                            />
                                        </div>
                                        <AboutVideo>

                                            <div style={{}}>คำอธิบายวิดีโอ </div>
                                            <div style={{ marginLeft: `auto` }}>
                                                <PopupMenu
                                                    data={subtitle}
                                                    menuitems={videoPopupItems}
                                                />
                                            </div>
                                        </AboutVideo>

                                        <AboutDescription>
                                            {video.description}
                                        </AboutDescription>

                                        <StatusWrapper>
                                            <StatusHeader>
                                                สถานะ :
                                                <StatusTag>
                                                    {video.approved && (<TagWrapper tag={TagInventory("APPROVED")} />)}
                                                    {video.published && (<TagWrapper tag={TagInventory("PUBLISH")} />)}
                                                </StatusTag>
                                            </StatusHeader>

                                            <StatusSelection>
                                                <Form>
                                                    <Form.Field>
                                                        <Radio
                                                            label="ยังไม่เผยแพร่"
                                                            name="radioGroup"
                                                            value={false}
                                                            checked={!publishStatus}
                                                            onChange={(event, { value }) => setPublishStatus(value)}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Radio
                                                            label="เผยแพร่"
                                                            name="radioGroup"
                                                            value={true}
                                                            checked={publishStatus}
                                                            onChange={(event, { value }) => setPublishStatus(value)}
                                                        />
                                                    </Form.Field>
                                                </Form>
                                            </StatusSelection>
                                        </StatusWrapper>


                                        <ButtonSelection hasVideoExpanded={hasVideoExpanded}>
                                            <ButtonWrapper>
                                                <Button
                                                    positive
                                                    onClick={() => { onClickPublishStatusVideo() }}
                                                    disabled={lastPublishStatus === publishStatus}
                                                >
                                                    <ButtonContent>
                                                        <Icon name="update" color={lastPublishStatus === publishStatus ? colors.lightGrey : colors.black} />
                                                        <label>อัปเดทสถานะ </label>
                                                    </ButtonContent>
                                                </Button>
                                            </ButtonWrapper>

                                            <ButtonWrapper style={hasVideoExpanded ? { marginLeft: `1.0rem` } : { marginTop: `1.0rem` }}>
                                                <Button
                                                    deleted={approveStatus ? false : true}
                                                    disabled={approveStatus ? true : false}
                                                    onClick={() => { onClickApproveVideo() }}
                                                >
                                                    <ButtonContent>
                                                        <Icon name={!video.approved ? "check" : "block"} color={approveStatus ? colors.lightGrey : colors.delete} />
                                                        <label>
                                                            {!video.approved ? "ยินยันวิดีโอ" : "ยกเลิกการยืนยันวิดีโอ"}
                                                        </label>
                                                    </ButtonContent>
                                                </Button>
                                            </ButtonWrapper>

                                            <ButtonWrapper style={hasVideoExpanded ? { marginLeft: `auto` } : { marginTop: `auto` }}>
                                                <Button
                                                    positive
                                                    disabled={!video.approved}
                                                    onClick={() => { onClickCreateTextToSpeech() }}
                                                >
                                                    <ButtonContent>
                                                        <Icon name="download" color={!video.approved ? colors.lightGrey : colors.black} />
                                                        <label>ดาวน์โหลดไฟล์เสียง</label>
                                                    </ButtonContent>
                                                </Button>
                                            </ButtonWrapper>


                                            <ButtonWrapper style={hasVideoExpanded ? { marginLeft: `auto` } : { marginTop: `0` }}>
                                                <Button
                                                    positive
                                                    onClick={() => { onClickExportADScript() }}
                                                >
                                                    <ButtonContent>
                                                        <Icon name="download" color={colors.black} />
                                                        <label>ดาวน์โหลดสคริปต์</label>
                                                    </ButtonContent>
                                                </Button>
                                            </ButtonWrapper>

                                        </ButtonSelection>
                                    </Container>
                                </Grid.Column>

                                <Grid.Column
                                    width={segmentColumn}
                                    textAlign={`center`}
                                    style={{
                                        height: `82.8vh`,
                                        overflowY: `auto`,
                                    }}
                                >
                                    <Grid style={GridStyle}>
                                        <Grid.Row style={{ padding: 0 }}>
                                            {isAdmin && (<AddSubtitleCard onClick={e => { onClickAddSubtitle() }} />)}
                                            {subtitles &&
                                                subtitles.map(
                                                    (subtitle, index) => {
                                                        return (
                                                            <SubtitleEditField
                                                                id={subtitle["subtitle"]["id"]}
                                                                key={subtitle["index"]}
                                                                subtitle={subtitle}
                                                                length={subtitles.length}
                                                                menuitems={menuitems}
                                                                placeholder=""
                                                                selected={activeSubtitle === subtitle["subtitle"]["id"] ? true : false}
                                                                onClick={e => onClickSubtitleEditField(e, subtitle, index)}
                                                                onClickSelectSubtitleCollection={onClickSelectSubtitleCollection}
                                                                onClickEditDurationTime={onClickEditDurationTime}
                                                                onSubtitleUpdated={(subtitle) => onSubtitleUpdated(subtitle)}
                                                                currentTime={currentTime}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </Grid.Row>
                                    </Grid>

                                </Grid.Column>
                            </Grid>
                        </ContentWrapper>
                    </>
                )}

            {
                /** Handle dialog rendering **/
                video && (
                    <>
                        <ChangeStatus
                            onClose={() => setOpenChangeStatus(false)}
                            onOpen={() => setOpenChangeStatus(true)}
                            open={openChangeStatus}
                            previousTag={publishStatus ? TagInventory("UNPUBLISH") : TagInventory("PUBLISH")}
                            newTag={publishStatus ? TagInventory("PUBLISH") : TagInventory("UNPUBLISH")}
                            handleChangeStatus={() => handleChangeStatus()}
                        />
                        <DeleteVideo
                            onClose={() => setOpenDeleteVideo(false)}
                            onOpen={() => setOpenDeleteVideo(true)}
                            open={openDeleteVideo}
                            videoCardProps={video}
                            handleDeleteVideo={() => handleDeleteVideo()}
                        />
                        {video.approved && (
                            <Disapprove
                                onClose={() => setOpenDisapprove(false)}
                                onOpen={() => setOpenDisapprove(true)}
                                open={openDisapprove}
                                handleDisapproveVideo={() =>
                                    handleDisapproveVideo(false)
                                }
                            />
                        )}
                        <AddSegment
                            onClose={() => setOpenAddSegment(false)}
                            onOpen={() => setOpenAddSegment(true)}
                            open={openAddSegment}
                            handleAddSubtitle={handleAddSubtitle}
                            duration={video['duration']}
                            subtitles={subtitles}
                        />
                    </>
                )
            }

            {
                subtitle && (
                    <>
                        <DeleteSubtitle
                            onClose={() => setOpenDeleteSubtitle(false)}
                            onOpen={() => setOpenDeleteSubtitle(true)}
                            open={openDeleteSubtitle}
                            subtitle={subtitle}
                            length={subtitles.length}
                            menuitems={menuitems}
                            handleDeleteSubtitle={() => handleDeleteSubtitle()}
                        />

                        <SubtitleEditorPage
                            onClose={() => {
                                setOpenSubtitleEditor(false)
                            }}
                            onOpen={() => setOpenSubtitleEditor(true)}
                            open={openSubtitleEditor}
                            subtitle={subtitle}
                            subtitlesCollection={subtitlesCollection}
                            onSubtitleUpdated={(subtitle) => onSubtitleUpdated(subtitle)}
                            onDeleteSubtitleCollection={(subtitle) => onDeleteSubtitleCollection(subtitle)}
                            forceUpdate={props.forceUpdate}
                            length={subtitles.length}
                        />

                        <EditSegment
                            edit={true}
                            onClose={() => setOpenEditSegment(false)}
                            onOpen={() => setOpenEditSegment(true)}
                            open={openEditSegment}
                            id={subtitle['subtitle']['id']}
                            startTime={subtitle['subtitle']['startTime']}
                            endTime={subtitle['subtitle']['endTime']}
                            handleEditSubtitleTime={(startTime, endTime) => { handleEditSubtitleTime(startTime, endTime, subtitle) }}
                            subtitles={subtitles}
                        />

                    </>
                )
            }

            <CreateVideoCard
                video={video}
                createMode={false}
                onClose={() => setOpenEditVideoInfo(false)}
                onOpen={() => setOpenEditVideoInfo(true)}
                onUpdateVideo={(data) => { onUpdateVideo(data) }}
                handleUpdateSubtitleTime
                open={openEditVideoInfo}
            />

            {activeLoader && <Loader />}

        </Container>
    )
}

const VideoPageBase = props => {
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const [_, updateState] = React.useState()
    const [, setNavSelection] = useRecoilState(NavSections)
    const [activeLoader, setActiveLoader] = useState(false)
    const [video, setVideo] = useState(null)
    const [subtitle, setSubtitle] = useState(null)
    const [subtitles, setSubtitles] = useState(null)

    useEffect(() => {
        if (!props.firebase) return () => { }

        const unsubscribe = props.firebase.getSubtitlesRefByVideoId(props.videoId)
            .onSnapshot(snapshot => {
                const subtitles = snapshot.docs.map((doc, index) => ({ index: index, subtitle: doc.data() }))
                setSubtitles(subtitles)
            })

        return () => unsubscribe()
    }, [props.firebase])

    useEffect(() => {
        let videoTemp = {}

        if (props.firebase) {
            const fetchData = async () => {
                setActiveLoader(true)
                await fetchVideo()
                await setNavigateSelection()
                setActiveLoader(false)
            }

            const fetchVideo = async () => {
                return new Promise((resolve) => {
                    props.firebase.getVideoRefById(props.videoId)
                        .onSnapshot(snapshot => {
                            videoTemp = snapshot.data()
                            videoTemp["id"] = props.videoId
                            setVideo(videoTemp)
                            resolve()
                        })
                })

            }

            const setNavigateSelection = () => {
                setNavSelection(prevSelection => [
                    {
                        key: "selection",
                        content: "วิดีโอ",
                        href: `/admin`,
                    },
                    {
                        key: "video",
                        content: `${videoTemp.title}  (ช่องเสียงทั้งหมด)`,
                        href: `/admin/video/${props.videoId}`,
                    },
                ])
            }
            fetchData()
        }
    }, [props.firebase, _])

    return (
        <>
            <Video
                {...props}
                video={video}
                subtitle={
                    subtitle
                        ? subtitle
                        : subtitles != null
                            ? subtitles[0]
                            : ""
                }
                subtitles={subtitles}
                forceUpdate={forceUpdate}
            />
            {activeLoader && <Loader />}
        </>
    )
}

const VideoPage = props => {
    return (
        <FirebaseContext.Consumer>
            {value => <VideoPageBase {...props} firebase={value} />}
        </FirebaseContext.Consumer>
    )
}

export default VideoPage
