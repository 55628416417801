import React, {useEffect } from "react"
import PropTypes from "prop-types"

import { useRecoilState, useRecoilValue } from 'recoil'
import { UniqueId } from '../../../recoil/atoms'
import { uniqueIdSelector } from '../../../recoil/selectors'

import { v4 as uuidv4 } from 'uuid'

const PublicRoute = ({ component: Component, location, ...rest }) => {


	const uniqueId = useRecoilValue(uniqueIdSelector)
    const [, setUniqueId] = useRecoilState(UniqueId)

    useEffect(() => {
        if (uniqueId == "") {
            const generationUUID = uuidv4()
            setUniqueId(generationUUID)
            localStorage.setItem("UNIQUE_ID", generationUUID)
        }
    }, [])

    return <Component {...rest} />
}

PublicRoute.propTypes = {
    component: PropTypes.any.isRequired,
}

export default PublicRoute