import React from 'react'
import styled from 'styled-components';
import Button from '../../Button';
import ConfirmModal from '../ConfirmModal';

const Modal = styled(ConfirmModal)`
    &.ui.modal {
        width: 444px;
        height: 314px;
    }
`

const StatusBox = styled.div`
    width: 200px;
    margin: 26px auto 36px auto;
`

const StatusLabel = styled.div`
    font-size: 16px;
    font-family: "Prompt-Light";
`

const Status = styled.div`
    font-size: 20px;
    font-family: "Prompt-Medium";
    text-align: center;
    margin: 12px 0;
    color: ${({ color }) => color};
`

function ChangeStatus(props) {
    const { previousTag, newTag, ...args } = props;

    const content = {
        header: "ต้องการอัพเดทสถานะของวิดีโอ"
    }

    return (
        <Modal {...args}>
            <Modal.Header>
                {content.header}
            </Modal.Header>
            <Modal.Content>
                <StatusBox>
                    <StatusLabel>
                        จากสถานะ
                    </StatusLabel>
                    <Status color={previousTag.color}>
                        {previousTag.label}
                    </Status>
                    <StatusLabel>
                        เป็นสถานะ
                    </StatusLabel>
                    <Status color={newTag.color}>
                        {newTag.label}
                    </Status>
                </StatusBox>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.onClose()}>
                    ยกเลิก
                </Button>
                <Button positive onClick={() => props.handleChangeStatus()}>
                    อัพเดทสถานะ
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ChangeStatus