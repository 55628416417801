import React from 'react'
import colors from './../../../styles/global.scss'
import styled from 'styled-components'
import loadingAnim from '../../../images/loading.gif'

const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 2000;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items:center;
`

const LoaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;    

 `
const LoaderImageWrapper = styled.div`
    padding: 1rem;
`

const LoaderTextWrapper = styled.div`
    {
        p {
            color: ${colors.white};
            font-size: 22px;
            font-family: "Prompt-Light";
        }
    }
`

const Loading = (props) => {

    return (
        <Container>
            <LoaderWrapper>
                <LoaderImageWrapper>
                    <img src={loadingAnim} width="70" height="70" />
                </LoaderImageWrapper>
                <LoaderTextWrapper>
                    <p>กำลังดำเนินการ...</p>
                    <p>กรุณาอย่าปิดแท็ป ขณะกำลังดำเนินการอยู่</p>
                </LoaderTextWrapper>

            </LoaderWrapper>
        </Container>
    )
}

export default Loading