import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import VideoList from "../VideosList"
import { Container, Grid } from "semantic-ui-react"

import { isMobile } from "react-device-detect"

import Loader from "../../../CustomComponents/Loader/Loader"

const VideoContainer = styled.div`
     {
        overflow-x: hidden;
        overflow-y: ${isMobile ? "hidden" : "scroll"};
        height: ${isMobile ? "auto" : "88.9vh"};
        width: 100%;
        && {
            margin: ${isMobile ? "0 !important" : null};
        }
        padding-top: 40px;
        padding-left: 6%;
        padding-right: 0%;
    }
`

const VideosWrapper = ({ children }) => (
    <VideoContainer>{children}</VideoContainer>
)

const Videos = props => {
    const [videos, setVideos] = useState([])
    const [activeLoader, setActiveLoader] = useState(true)

    useEffect(() => {
        if (props.firebase != null) {
            const fetchData = async () => {
                setActiveLoader(true)
                await getVideosList()
                setActiveLoader(false)
            }
            fetchData()
        }
    }, [props.firebase])

    const getVideosList = async () => {
        return new Promise(async (resolve) => {
            let query = await props.firebase.onSnapshotVideosList()
            query = query.where("published", "==", true)
            query.orderBy("createdDate", "desc").onSnapshot(async snapshots => {
                const videos = snapshots.docs
                let videoArr = []

                for (const video of videos) {
                    let videoTemp = video.data()
                    videoTemp["id"] = video.id

                    let subtitles = await video.ref.collection("subtitles").get()
                    videoTemp["subtitlesLength"] = subtitles.size
                    videoArr.push(videoTemp)
                }
                setVideos(videoArr)
                resolve()
            })
        })
    }

    return (
        <VideosWrapper>
            <Grid columns={4} doubling style={{ margin: 0 }}>
                <VideoList
                    videos={videos}
                    onClickVideoListener={props.onClickVideoListener}
                    menuitems={props.menuitems}
                />
            </Grid>
          
            {activeLoader && <Loader />}
        </VideosWrapper>
    )
}

export default Videos
