import React from 'react'
import styled from 'styled-components'
import ConfirmModal from '../ConfirmModal'
import VideoCard from '../../VideoCard'
import Button from '../../Button'
import colors from '../../../../styles/global.scss'

const Modal = styled(ConfirmModal)`
    &.ui.modal {
        width: 408px;
        height: 546px;
        padding-left: 24px;
        padding-right: 24px;

        .content {
            pointer-events: none;
            margin-top: 30px;
            margin-bottom: 24px;

            .ui.card {
                margin: 0;
            }
        }

        .actions {
            .ui.button {
                width: 82px;
                height: 36px;
            }

            .ui.positive.button {
                background-color: ${colors.delete};
            }
        }
    }
`

function DeleteVideo(props) {
    const { videoCardProps, ...args } = props;
    
    return (
        <Modal {...args}>
            <Modal.Header>
                ต้องการลบวิดีโอนี้
            </Modal.Header>
            <Modal.Content>
                <VideoCard video={videoCardProps} />
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.onClose()}>
                    ยกเลิก
                </Button>
                <Button positive onClick={() => props.handleDeleteVideo()}>
                    ลบวิดีโอ
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeleteVideo