import { useEffect, useMemo, useRef, useState } from "react"

function stringToDuration(format) {
    let [second] = format.split(":")

    if (+second >= 60) return false

    return +second
}

let MOUSE_PRESS_TIMEOUT, MOUSE_PRESS_INTERVAL

const BEFORECOLON = 1,
    INPUT_LENGTH = 2

const KEYUP = 38,
    KEYDOWN = 40

const MOUSE_PRESS_DELAY = 300,
    MOUSE_PRESS_INTERVAL_TIME = 50

const INPUT_LIMIT = 60 //in seconds

export default function useDuration(placeholder) {
    const inputRef = useRef({})
    const [cursor, setCursor] = useState(0)
    const [duration, setDuration] = useState(placeholder)


    const formatDuration = useMemo(() => {

        if (duration >= INPUT_LIMIT) {
            setDuration(0)
        } else if (duration < 0) {
            setDuration(INPUT_LIMIT - 1)
        }

        const second = (duration % 60).toString().padStart(2, "0")
        const res = `${second}`

        return res
    }, [duration])

    function handleDurationChange(event) {
        const inputEl = event.target


        setDuration(parseInt(inputEl.value, 10))

        event.persist()
    }

    function handleKeyPress(event) {
        const keyCode = event.keyCode

        if (keyCode === KEYUP) return handleKeyUp(event)
        if (keyCode === KEYDOWN) return handleKeyDown(event)
    }

    function handleKeyUp(event) {
        event.preventDefault()
        setDuration(previousDuration => previousDuration + 1)
    }
    function handleKeyDown(event) {
        event.preventDefault()
        setDuration(previousDuration => previousDuration - 1)
    }

    function handleClickIncrement(event) {
        event.preventDefault()
        setDuration(previousDuration => previousDuration + 1)
    }

    function handleClickDecrement(event) {
        event.preventDefault()
        setDuration(previousDuration => previousDuration - 1)
    }

    function handleMouseDownIncrement(event) {
        event.preventDefault()
        MOUSE_PRESS_TIMEOUT = setTimeout(function () {
            MOUSE_PRESS_INTERVAL = setInterval(function () {
                setDuration(previousDuration => previousDuration + 1)
            }, MOUSE_PRESS_INTERVAL_TIME);
        }, MOUSE_PRESS_DELAY);
    }

    function handleMouseDownDecrement(event) {
        event.preventDefault()
        MOUSE_PRESS_TIMEOUT = setTimeout(function () {
            MOUSE_PRESS_INTERVAL = setInterval(function () {
                setDuration(previousDuration => previousDuration - 1)
            }, MOUSE_PRESS_INTERVAL_TIME);
        }, MOUSE_PRESS_DELAY);
    }

    function handleMousePress() {
        clearTimeout(MOUSE_PRESS_TIMEOUT);
        clearInterval(MOUSE_PRESS_INTERVAL);
    }

    useEffect(() => {
        let newCursor = cursor + 1
        // if (cursor === BEFORECOLON) newCursor += 1
        // console.log("newselection : ", newCursor)
        // inputRef.current.selectionStart = newCursor
        // inputRef.current.selectionEnd = newCursor
    }, [cursor, inputRef])

    function increment(startTime) {
        setDuration(startTime + 1)
    }

    function resetCursor() {
        // inputRef.current.selectionStart = 0
        // inputRef.current.selectionEnd = 0
    }

    return [
        duration,
        formatDuration,
        handleDurationChange,
        handleKeyPress,
        inputRef,
        increment,
        resetCursor,
        handleClickIncrement,
        handleClickDecrement,
        handleMouseDownIncrement,
        handleMouseDownDecrement,
        handleMousePress
    ]
}
