import React, { useState } from "react"
import styled from "styled-components"
import Button from "../../Button"
import ConfirmModal from "../ConfirmModal"
import colors from "../../../../styles/global.scss"
import useDuration from "./useDuration"
import Icon from "../../../CustomComponents/Icon"
import * as Utils from "../../../../utils/utils"
import checkOverlapOfRange from "../../../Utils/checkOverlapOfRange"
import ErrorPage from "../../ErrorCard/ErrorPage"
const Modal = styled(ConfirmModal)`
  &.ui.modal {
    width: 444px;
    height: 384px;
  }
`

const TimeBox = styled.div`
  margin: 22px 0 42px 0;
`

const TimeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-top: 16px;
  }
`

const TimeLabel = styled.div`
  font-size: 20px;
  font-family: "Prompt";
  color: ${colors.lightGrey};
  width: 100px;
  margin-right: 16px;
`

const TimeInput = styled.input`
  font-size: 14px;
  font-family: "Prompt-Medium";
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.lightGrey};
  width: 60px;
  height: 40px;
  border: none;
  border-radius: 10px;
  outline: none;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
`

const TimeInputWrapper = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TimeInputButton = styled.div`
    cursor: pointer;
`

const TimeDataInput = (props) => {
    const { initTime } = props
    const [
        time,
        formatTime,
        handleTimeChange,
        handleKeyPress,
        startRef,
        _,
        resetCursor,
        handleClickIncrement,
        handleClickDecrement,
        handleMouseDownIncrement,
        handleMouseDownDecrement,
        handleMousePress
    ] = useDuration(initTime || 0)

    props.onChange(time)

    return (
        <TimeInputWrapper>
            <TimeInputButton
                onClick={handleClickIncrement}
                onMouseDown={handleMouseDownIncrement}
                onMouseUp={handleMousePress}
                onMouseLeave={handleMousePress}
            >
                <Icon name="arrowUp" color={colors.primary} />
            </TimeInputButton>
            < TimeInput
                ref={startRef}
                value={formatTime}
                onChange={handleTimeChange}
                onKeyDown={handleKeyPress}
                onClick={resetCursor}
                type="number"

            />
            <TimeInputButton
                onClick={handleClickDecrement}
                onMouseDown={handleMouseDownDecrement}
                onMouseUp={handleMousePress}
                onMouseLeave={handleMousePress}
            >
                <Icon name="arrowDown" color={colors.primary} />
            </TimeInputButton>
        </TimeInputWrapper>
    )
}

function useTimeInput() {
    const [time, setTime] = useState(time)

    function handleTimeChange(value) {
        setTime(value)
    }
    return [
        time,
        handleTimeChange
    ]
}

const TimeFormatInput = (props) => {
    const { initTime, duration } = props
    let [_, hours, minutes, seconds] = Utils.createTimeFormat(initTime || 0)
    const handleOnTimeFormatChange = (value, format) => {
        switch (format) {
            case 'hours':
                hours = value * 60 * 60
                break
            case 'minutes':
                minutes = value * 60
                break
            case 'seconds':
                seconds = value
                break
        }

        props.onChange(convertToSecs(hours, minutes, seconds))
    }

    const convertToSecs = (hours, minutes, seconds) => {
        return hours + minutes + seconds
    }

    return (
        <>
            {
                (duration >= 3600 || initTime >= 3600) &&
                <>
                    <TimeDataInput
                        initTime={hours}
                        onChange={(value) => handleOnTimeFormatChange(value, 'hours')}
                    />
                    :
                </>
            }
            <TimeDataInput
                initTime={minutes}
                onChange={(value) => handleOnTimeFormatChange(value, 'minutes')}
            />
            :
            <TimeDataInput
                initTime={seconds}
                onChange={(value) => handleOnTimeFormatChange(value, 'seconds')}
            />
        </>
    )
}


function AddSegment(props) {
    const
        {
            id,
            startTime,
            endTime,
            duration,
            edit,
            subtitles,
            handleAddSubtitle,
            handleEditSubtitleTime,
            ...args
        } = props
    const [startTimeInput, handleStartTimeChange] = useTimeInput()
    const [endTimeInput, handleEndTimeChange] = useTimeInput()

    const [openError, setOpenError] = useState(false)

    const checkOverlapTime = () => {
        for (let sub of subtitles) {
            let isOverlap = false
            if (sub['subtitle']['id'] !== id) {
                isOverlap = checkOverlapOfRange({ start: startTimeInput, end: endTimeInput }, { start: sub['subtitle']['startTime'], end: sub['subtitle']['endTime'] })
            }
            if (isOverlap)
                return true
        }
        return false
    }

    const checkTimeInputIsValid = () => {
        let isOverlap = checkOverlapTime()
        if (isOverlap) {
            return false
        } else if (startTimeInput >= endTimeInput) {
            return false
        } else if ( startTimeInput > duration || endTimeInput > duration) {
            return false
        } 
        return true
    }

    return (
        <>
            <Modal {...args}>
                {!edit && <Modal.Header>เพิ่มช่องเสียงสำหรับบรรยาย</Modal.Header>}
                {edit && <Modal.Header>แก้ไชช่วงเวลาสำหรับคำบรรยาย</Modal.Header>}

                <Modal.Content>
                    <TimeBox>
                        <TimeInputContainer>
                            <TimeLabel>เวลาเริ่มต้น</TimeLabel>
                            <TimeFormatInput
                                duration={duration || null}
                                initTime={startTime || null}
                                onChange={handleStartTimeChange}
                            />

                        </TimeInputContainer>
                        <TimeInputContainer>
                            <TimeLabel>เวลาจบ</TimeLabel>
                            <TimeFormatInput
                                duration={duration || null}
                                initTime={endTime || null}
                                onChange={handleEndTimeChange}
                            />
                        </TimeInputContainer>

                    </TimeBox>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => {
                        setOpenError(false);
                        props.onClose()
                    }}>
                        ยกเลิก
                    </Button>
                    {
                        !edit &&
                        <Button positive onClick={() => {
                            let isValid = checkTimeInputIsValid()
                            if (!isValid) {
                                setOpenError(true)
                                return
                            }
                            handleAddSubtitle(startTimeInput, endTimeInput)

                        }}>
                            เพิ่มช่อง
                    </Button>
                    }
                    {
                        edit &&
                        <Button positive onClick={() => {
                            let isValid = checkTimeInputIsValid()
                            if (!isValid) {
                                setOpenError(true)
                                return
                            }
                            handleEditSubtitleTime(startTimeInput, endTimeInput)
                        }}>
                            แก้ไข
                    </Button>
                    }
                </Modal.Actions>
            </Modal>
            {
                openError && <ErrorPage
                    title={`คุณเลือกเวลาซ้อนทับช่องเสียงอื่น`}
                    description={`กรุณาเลือกเวลาใหม่ โดยระบุช่วงเวลาที่ไม่ซ้อนทับกับช่องเสียงเดิม`}
                    onClose={() => { setOpenError(false) }}
                />
            }

        </>
    )
}


export default AddSegment
