import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import * as Utils from '../../../utils/utils'
import { TextArea as TextAreaBase } from '../../CustomComponents/TextArea'
import PopupMenu from '../../CustomComponents/PopupMenu'
import { AttributeItem as AttributeItemBase } from '../../CustomComponents/AttributeItem'
import {
    Card as CardBase,
    CardMeta as CardMetaBase,
    CardTitle as CardTitleBase,
    CardContent as CardContentBase,
    CardFooter as CardFooterBase
} from '../../CustomComponents/Card'

const CardWrapper = styled.div`
    padding: 0;
    margin: 0;
`
const Card = styled(CardBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                backgroundColor: colors.cardHover,
                width: `360px`,
                height: `212px`,
                marginLeft: `0px`,
            }
        })

    return ({
        style: {
            backgroundColor: props.hoverState ? colors.cardHover : colors.card,
            width: `360px`,
            height: `212px`,
            marginLeft: `0px`

        }
    })

})``

const CardMeta = styled(CardMetaBase).attrs((props) => ({
    style: {
        paddingTop: `0.7500em`,
        paddingBottom: `0`
    }
}))``

const CardTitle = styled(CardTitleBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                color: colors.primary
            }
        })
    return ({
        style: {
            color: props.hoverState ? colors.primary : colors.white,
        }
    })

})`
    -webkit-box-orient: unset;
`

const CardContent = styled(CardContentBase).attrs((props) => ({

}))``

const CardFooter = styled(CardFooterBase).attrs((props) => ({
    style: {
        paddingTop: `0`,
        paddingBottom: `0`
    }
}))``

const TextArea = styled(TextAreaBase).attrs((props) => {
    if (props.selected)
        return ({
            style: {
                backgroundColor: `${colors.card}`,
                cursor: `pointer`,
                minWidth: `100%`,
                minHeight: `84px`,
                maxHeight: `84px`
            }
        })
    return ({
        style: {
            backgroundColor: props.hoverState ? colors.card : colors.grey,
            cursor: `pointer`,
            minWidth: `100%`,
            minHeight: `84px`,
            maxHeight: `84px`
        }
    })

})``

const AttributeItem = styled(AttributeItemBase).attrs((props) => ({
    style: {
        flex: `1`
    }
}))``

const MenuButton = styled.div`
{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    text-align: end;
    align-self: flex-end;
}
`

const SubtitleChoice = (props) => {
    const subtitle = props.subtitle
    const createdDate = Utils.formatTimeStamp(new Date(subtitle['subtitle']['createdDate']))

    const [hoverState, setHoverState] = useState(false)

    return (
        <CardWrapper onClick={(e) => { props.onClickSelectSubtitle(e, subtitle) }}>
            <Card
                {...props}
                onMouseOver={() => { setHoverState(true) }}
                onMouseLeave={() => { setHoverState(false) }}
                hoverState={hoverState}
            >
                <CardMeta tags>
                    <MenuButton onClick={(e) => { e.stopPropagation(); }}>
                        {
                            props.menuitems &&
                            <PopupMenu data={subtitle} menuitems={props.menuitems} />
                        }
                    </MenuButton>
                </CardMeta>
                <CardTitle hoverState={hoverState}>
                    ตัวเลือกที่ {props.index + 1} จาก {props.length}
                </CardTitle>
                <CardContent >
                    <TextArea
                        {...props}
                        hoverState={hoverState}
                        disabled
                        value={subtitle['subtitle']['text']} />
                </CardContent>

                <CardFooter>
                    <AttributeItem icon="calendar" color="white" value={createdDate} />
                </CardFooter>
            </Card>
        </CardWrapper>
    )
}

SubtitleChoice.propTypes = {

};


export default SubtitleChoice
