import React from 'react';
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Button as SemanticButton } from 'semantic-ui-react';

const ButtonStyled = styled(SemanticButton).attrs((props) => {

    if (props.deleted)
        return ({
            style: {
                borderStyle: `solid`,
                borderWidth: `1px`,
                borderColor: colors.delete,
                color: colors.delete,
                backgroundColor: `transparent`
            }
        })
    if (props.disabled)
        return ({
            style: {
                color: colors.lightGrey,
                backgroundColor: colors.black
            }
        })
})`
    &.ui.button {
        border-radius: 12px;
        font-family: Prompt-Medium;
        font-size: 0.8750em;
        margin: 0;
        background-color: ${`transparent`}
    }


    &.ui.positive.button {
        background-color: ${colors.primary};
        color: ${colors.background}
    }

    &.ui.negative.button {
        background-color: transparent;
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.primary};
        color: ${colors.primary};
    }
`

const Button = (props) => {
    return (
        <ButtonStyled {...props} />
    )
}

export default Button