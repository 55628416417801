import React, { useState, useEffect } from "react"
import { Form, Input as InputBase } from "semantic-ui-react"
import styled from "styled-components"
import Button from "../../Button"
import Icon from "../../Icon"
import ConfirmModal from "../ConfirmModal"
import colors from "../../../../styles/global.scss"
import { useRecoilState } from "recoil"
import { Role, IsLogin, userType } from "../../../../recoil/atoms"
import authenticate from "./authenticate"
import { ROLES } from "../../../../constants/authenticate"

const Modal = styled(ConfirmModal)`
  &.ui.page.dimmer {
    background-color: rgba(0, 0, 0, 1);
  }

  &.ui.modal {
    width: 684px;

    .header {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;

      svg {
        width: 48px;
      }
    }

    .content {
      margin: 24px 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      label {
        font-size: 22px;
        font-family: "Prompt-Light";
        margin-right: 24px;
      }

      .ui.icon.input {
        flex: 1;
        border-radius: 12px;
        height: 60px;
      }
    }

    .actions {
      text-align: right;
      .ui.button {
        width: 84px;
      }
    }
  }
`

const IconContainer = styled.i`
  cursor: pointer;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0;
  right: 5px;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  transition: opacity 0.3s ease;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled(InputBase)`
  background: ${colors.cardHover};

  & input {
    color: white !important;
    background: transparent !important;
    ${({ danger }) =>
    danger ? `border: 3px solid ${colors.delete} !important;` : ""}

    transition: all 1s;
  }
`

const TriggerButton = styled.span`
  cursor: pointer;
`

function Login(props) {
  const [open, setOpen] = useState(false)
  const [_, setRole] = useRecoilState(Role)
  const [isLogin, setIsLogin] = useRecoilState(IsLogin)


  function handleTriggerClick() {
    setOpen(previousState => !previousState)
  }

  const [password, setPassword] = useState("")
  const [passwordIncorrect, setPasswordIncorrect] = useState(false)

  function handlePasswordEnter(data) {
    const { value: enteredPassword } = data

    setPassword(enteredPassword)
  }

  const [passwordVisibility, setPasswordVisibility] = useState(false)

  function togglePasswordVisibility() {
    setPasswordVisibility(previousVisibility => !previousVisibility)
  }

  async function handleLogin() {
    const { passwordCorrect } = await authenticate(password)

    if (passwordCorrect) {
      setRole(ROLES.ADMIN)
      setIsLogin(true)
      localStorage.setItem("ROLE", JSON.stringify(ROLES.ADMIN))    
      localStorage.setItem("LOGIN_STATE", true)    
    }
    setPasswordIncorrect(!passwordCorrect)
    setOpen(false)
  }

  return (
    <Modal
      {...props}
    //   open={open}
    // trigger={<TriggerButton onClick={handleTriggerClick}>เข้าสู่ระบบแอดมิน</TriggerButton>}
    >
      <Form onSubmit={handleLogin}>
        <Modal.Header>
          <Icon name="enter" color={colors.primary} />
          <span>เข้าสู่ระบบแอดมิน</span>
        </Modal.Header>
        <Modal.Content>
          <label>รหัสผ่าน</label>
          <Input
            type={passwordVisibility ? "text" : "password"}
            onChange={(_, data) => handlePasswordEnter(data)}
            icon={
              <IconContainer onClick={togglePasswordVisibility}>
                <Icon
                  name={passwordVisibility ? "eyeslash" : "eye"}
                  color="white"
                  link={true}
                />
              </IconContainer>
            }
            danger={passwordIncorrect}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button positive type="submit">
            เข้าสู่ระบบ
          </Button>
        </Modal.Actions>
      </Form>
    </Modal>
  )
}

export default Login
