import React from 'react'
import colors from './../../../styles/global.scss'
import styled from 'styled-components'
import Icon from '../Icon'
import Button from '../Button'

const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 2000;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items:center;
`

const ErrorPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;    

 `
const ErrorImageWrapper = styled.div`
    padding: 1rem;
`

const ErrorTextWrapper = styled.div`
    {
        p {
            color: ${colors.white};
            font-size: 22px;
            font-family: "Prompt-Light";
        }
    }
`


const ErrorButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-self: flex-end;
    margin-top: 2.0rem;
    margin-bottom: 2.0rem;
`
const ErrorPage = (props) => {

    return (
        <Container>
            <ErrorPageWrapper>
                <ErrorImageWrapper>
                    {/* <img src={errorInfo} width="70" height="70" /> */}
                    <Icon name={`errorInfo`} color={colors.primary} />
                </ErrorImageWrapper>
                <ErrorTextWrapper>
                    <p>{props.title}</p>
                    <p>{props.description}</p>
                </ErrorTextWrapper>

                <ErrorButtonWrapper>
                    <Button positive onClick={props.onClose}>
                        ตกลง
                    </Button>
                </ErrorButtonWrapper>
            </ErrorPageWrapper>
        </Container>
    )
}

export default ErrorPage