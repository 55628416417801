import { atom } from 'recoil';
import { HOME } from '../constants/routes'
import { ROLES } from '../constants/authenticate'

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue))
    }
    onSet(newValue => {
        localStorage.setItem(key, JSON.stringify(newValue))
    })
}

export const PageState = atom({
    key: "PAGE_STATE",
    default: HOME
})

export const Role = atom({
    key: "USER_ROLE",
    default: ROLES.USER,
    effects_UNSTABLE: [
        localStorageEffect('ROLE')
    ]
})

export const IsLogin = atom({
    key: "LOGIN_IN",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('LOGIN_STATE')
    ]
})

export const NavSections = atom({
    key: "NAV_SECTIONS",
    default: [{ key: "home" }],
})

export const IsShowError = atom({
    key: "IsShowError",
    default: false
})

export const UniqueId = atom({
    key: "UNIQUE_ID",
    default: "",
    effects_UNSTABLE: [
        localStorageEffect('UNIQUE_ID')
    ]
})