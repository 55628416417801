import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Card as SemanticCard } from 'semantic-ui-react'

export const CardMeta = styled.div`
	{
		display: flex;
		flex-direction: row;
		align-items: center;

		color: white;
		font-size: 0.8rem;
		
		padding-left: 1.5em;
		padding-right: 1.5em;
		padding-top : 1em;
		padding-bottom : 1em;
		&.tags {
			padding-top: 90px;
		}	
	}
`

export const CardTitle = styled.div`
	{
		white-space: inherit;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;  
		overflow: hidden;
		text-overflow: ellipsis;
		height: 100%;
		flex-direction: row;
		align-items: center;
		padding-left: 1.0em;
		padding-right: 1.0em;
		color: white;

		font-family: Prompt-Regular;
		font-size: 1.2em;		
		line-height: 1.5rem;
		:hover{
			color:${colors.primary};
		}	
	}
`
export const CardContent = styled.div`
	{
		display: flex;
		flex-direction: row;
		align-items: center;

		color: white;
		font-size: 0.8rem;
		
		padding-left: 1.5em;
		padding-right: 1.5em;
		padding-top : 1em;
		padding-bottom : 1em;
		&.tags {
			padding-top: 90px;
		}	
	}
`

export const CardFooter = styled.div`
	{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		color: white;
		font-size: 0.8rem;		
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-top : 1em;
		padding-bottom : 1em;
		height: inherit;
	}
`

export const Card = styled(SemanticCard).attrs((props) => ({
    // ${``}
}))`
	&.ui.card {
        width: 360px;
        height: 382px;
		border-radius: 18px;
		-webkit-clip-path: inset(0 round 18px);
		clip-path: inset(0 round 18px);
		background-color: ${colors.card};     
		box-shadow: none;   
		margin-left: 4px;
		margin-right: 4px;      
      
		:first-child {
			margin-top: 1em;
		}
      
    }
    &.ui.card:hover{
        background-color: ${colors.cardHover};    
		${CardTitle} {
            color:#F6CA06;
		}
		cursor: pointer;
	}
	
	&.ui.card:active{
        background-color:  ${colors.cardHover};  
		${CardTitle} {
            color:${colors.primary};
		}
		cursor: pointer;
    }
	`;
