import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { HOME } from "../constants/routes"

import styled from "styled-components"
import colors from "./../styles/global.scss"
import Icon from "../components/CustomComponents/Icon"
import { useRecoilValue, useRecoilState } from "recoil"
// import { isAdminSelector } from "../recoil/selectors"

import Login from "./CustomComponents/ConfirmedPage/Login"
import { Role } from "../recoil/atoms"
import { ROLES } from "../constants/authenticate"

const HeaderContainer = styled.header`
     {
        // position: fixed;
        display: flex;
        background-color: ${colors.black};
        width: 100%;
        z-index: 999;
    }
`

const HeaderWrapper = styled.div`
     {
        min-height: 60px;
        display: flex;
        flex-direction: row;
    }
`

const NavButton = styled.div`
     {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        min-height: 60px;
        cursor: pointer;
    }
`
const Title = styled.div`
     {
        display: flex;
        flex: 1;
        align-items: center;
        color: ${colors.white};
        font-family: Prompt-Bold;
        font-size: 1.5rem;
        cursor: pointer;
    }
`

const SignInUser = styled.div`
     {
        display: flex;
        align-items: center;
        color: ${colors.white};
        font-family: Prompt-SemiBold;
        font-size: 1.125rem;
        padding-right: 15px;
    }
`

const Header = props => {
    // const isAdmin = useRecoilValue(isAdminSelector)

    const [_, setRole] = useRecoilState(Role)

    useEffect(() => {
        let pathname = window.location.pathname
        if (!pathname.includes("admin")) {
            setRole(ROLES.USER)
            localStorage.setItem("ROLE", JSON.stringify(ROLES.USER))
            localStorage.setItem("LOGIN_STATE", false)
        }
    }, [])



    return (
        <HeaderContainer>
            <HeaderWrapper>
                <NavButton onClick={props.onClickHamburger}>
                    <Icon name="hamburger" color={colors.white} />
                </NavButton>
                <Title onClick={() => {
                    let pathname = window.location.pathname

                    if (!pathname.includes("admin")) {
                        navigate(`${HOME}`, { replace: true })

                    } else {
                        navigate(`/admin${HOME}`, { replace: true })
                    }
                }}>
                    โวหาร (AppV0.0.22 ML V0.2.0)
                </Title>
                {/* <SignInUser>
				{isAdmin ? "แอดมิน" : (
					<Login />
				)}
			</SignInUser> */}
            </HeaderWrapper>
        </HeaderContainer>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
