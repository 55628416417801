import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "../Icon"

const MenuItemWrapper = styled.div.attrs(props => {
    if (props.disabled) {
        return {
            style: {
                pointerEvents: "none",
                borderColor: "red",
            },
        }
    }
})`
     {
        display: flex;
        flex-direction: row;
        padding-right: 5px;
    }
`

const MenuIcon = styled.div`
     {
        display: flex;
        min-width: 32px;
        min-height: 32px;
        justify-content: center;
        align-items: center;
        text-align: center;

        svg {
            width: 15px;
            height: 15px;
        }
    }
`

const MenuText = styled.div`
     {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Prompt-Regular;
        font-size: 12px;
    }
`

const MenuItem = ({
    onClick,
    icon,
    text,
    color,
    hoverColor,
    disabled = false,
    disabledColor = "#575757",
}) => {
    const [hoverState, setHoverState] = useState(
        disabled ? disabledColor : color
    )
 
    return (
        <MenuItemWrapper
            onClick={disabled ? null : onClick}
            onMouseEnter={() => {
                disabled ? null : setHoverState(hoverColor)
            }}
            onMouseLeave={() => {
                disabled ? null : setHoverState(color)
            }}
            disabled={disabled}
        >
            <MenuIcon>
                <Icon name={icon} color={hoverState} />
            </MenuIcon>
            <MenuText>
                {text}
            </MenuText>
        </MenuItemWrapper>
    )
}

MenuItem.PropType = {
    color: PropTypes.string.isRequired,
}

export default MenuItem
