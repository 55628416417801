import React from 'react';
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

const TextAreaWrapper = styled(SemanticTextArea).attrs((props) => {
    return ({
        style: {
            width: "100%",
            // heigth: "10%",
            borderWidth: `0px`,
            borderRadius: `12px`,
            color: `${colors.white}`,
            fontFamily: `Sarabun-Regular`,
            fontSize: `1.0rem`,
            padding: `10px`,
        }
    })
})`
    {
        height: 0vh;   
    }

`

const TextArea = (props) => {
    return (
        <TextAreaWrapper {...props} />
    )
}

export default TextArea