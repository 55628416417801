import React from 'react';
import styled from 'styled-components'
import colors from '../../../styles/global.scss'
import { Input } from 'semantic-ui-react';

const InputFieldWrapper = styled(Input)`
    &.ui.input {  
        input {
            background-color: ${colors.cardHover};
            border-radius: 12px;
            color: ${colors.white};
            font-family: Sarabun-Regular;
            font-size: 1.125em;    
        }

        input[type=text]:focus {
            background-color: ${colors.cardHover};
            border-radius: 12px;
            color: ${colors.white};
        }
    }
`

const InputField = (props) => {
    return (
        <InputFieldWrapper {...props} />
    )
}

export default InputField