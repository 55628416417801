import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import colors from "../../../styles/global.scss"
import { TagInventory } from "../../../constants/tag-inventory"
import { Card, CardMeta, CardTitle, CardFooter } from "../Card"
import Icon from "../Icon"
import Tag from "../Tag"
import PopupMenu from "../PopupMenu"
import AttributeItem from "../AttributeItem"
import { motion } from "framer-motion"
import * as Utils from "../../../utils/utils"

import { useRecoilValue } from "recoil"
import { isAdminSelector } from "../../../recoil/selectors"

const MenuButton = styled.div`
     {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        text-align: end;
        align-self: flex-end;
    }
`

const AddVideo = styled(Card).attrs(props => ({}))`
    &.ui.card {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.ui.card:hover {
        border-color: transparent;
    }
`

const VideoCardMotion = ({ children }) => {
    return (
        <motion.div whileTap={{ scale: 0.9 }} style={{ padding: 0 }}>
            {children}
        </motion.div>
    )
}

const ImageWrapper = styled.div.attrs(props => ({
    style: {
        display: "flex",
        width: "100%",
        height: "50%"
    },
}))``

export const AddVideoCard = props => {
    const [hoverState, setHoverState] = useState(colors.white)

    return (
        <VideoCardMotion>
            <AddVideo
                onMouseOver={() => {
                    setHoverState(colors.primary)
                }}
                onMouseLeave={() => {
                    setHoverState(colors.white)
                }}
            >
                <Icon name="add" color={hoverState} />

                {props.duration}
            </AddVideo>
        </VideoCardMotion>
    )
}

const TagWrapper = ({ tag }) => {
    return <Tag {...tag}>{tag.label}</Tag>
}
const VideoCard = (props) => {
    const isAdmin = useRecoilValue(isAdminSelector)
    const video = props.video
    const [durationFormat] = Utils.createTimeFormat(video.duration)
    let youtubeId = Utils.getYouTubeID(video.url)
    return (
        <div >

            <Card>
                <ImageWrapper>
                    <img style={{ width: '100%', borderRadius: '18px 18px 0 0', objectFit: 'cover' }} src={`https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`} />
                </ImageWrapper>
                <CardMeta tags>
                    {isAdmin && video.approved && <TagWrapper tag={TagInventory("APPROVED")} />}
                    {isAdmin && video.published && <TagWrapper tag={TagInventory("PUBLISH")} />}
                    < MenuButton onClick={(e) => { e.stopPropagation(); }}>
                        <PopupMenu data={video} menuitems={props.menuitems} />
                    </MenuButton>
                </CardMeta>
                <CardTitle>
                    {video.title}
                </CardTitle>
                <CardFooter>
                    <AttributeItem icon="hourglass" color="white" value={durationFormat} />
                    <AttributeItem icon="stack" color="white" value={`ช่องบรรยาย ${video.subtitlesLength} ช่อง`} />
                </CardFooter>
            </Card>
        </div >
    )
}

VideoCard.propTypes = {
}

export default VideoCard
