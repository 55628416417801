import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "../../../styles/global.scss"
import { Breadcrumb as SemanticBreadcrumb } from "semantic-ui-react"
import Logout from "../ConfirmedPage/Logout"
import { useRecoilValue } from "recoil"
import { isAdminSelector, navSectionsSelector } from "../../../recoil/selectors"

const BreadcrumbContainer = styled.div`
    // margin-top: 60px;
    display: flex;
    background-color: ${colors.grey};
`

const BreadcrumbWrapper = styled(SemanticBreadcrumb)`
    &.ui.breadcrumb {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 48px;
        padding-left: 16px;
        padding-right: 16px;
        flex: 1;

        a {
            color: ${colors.white};
            font-family: Prompt;
        }
        div {
            color: ${colors.white};
            font-family: Prompt;
        }
        * {
            color: ${colors.white};
            opacity: 1;
        }
    }
`

const Breadcrumb = props => {
    const [sections, setSections] = useState([])

    const isAdmin = useRecoilValue(isAdminSelector)
    const navSections = useRecoilValue(navSectionsSelector)
    useEffect(() => {
        setSections(navSections)
    }, [navSections])

    return (
        <BreadcrumbContainer>
            <BreadcrumbWrapper icon="right angle" sections={sections} />
            {isAdmin && <Logout />}
        </BreadcrumbContainer>
    )
}

export default Breadcrumb
