import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

const TagOverrideStyled = styled(Label).attrs(props => ({
    style: {
        color: `${props.customcolor}`,
        border: `1px solid ${props.customcolor}`
    }
}))`
        &.ui.label {
            color: #FFFFF;
            background-color: transparent;
            border: 1px solid  #FFFFF;
            border-radius: 0px;
            min-width: 72px;
            max-width: 84px;
            height: 20px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center; 
            font-family: Prompt-Medium;
            font-size: 10px;
            padding: 0px;
        }
    `;

const TagWrapper = ({ color, children }) => {
    return <TagOverrideStyled customcolor={color}>{children}</TagOverrideStyled>
}

const Tag = ({ color, children }) => (
    <TagWrapper color={color} > {children} </TagWrapper>
)

Tag.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Tag