import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Router } from "@reach/router"
import { Helmet } from "react-helmet"
import PublicRoute from "../components/CustomComponents/PublicRoute"
import PrivateRoute from "../components/CustomComponents/PrivateRoute"
import Layout from "../components/Layout"
import Header from "../components/header"
import {
    NavigationBar,
    NavigationSideBar,
} from "../components/CustomComponents/NavigationBar/NavigationBar.js"
import Breadcrumb from "../components/CustomComponents/Breadcrumb"

import AdminSelectionPage from "../components/Admin/SelectionPage"
import AdminVideoPage from "../components/Admin/VideoPage/Video"

import UserSelectionPage from "../components/User/SelectionPage"
import UserVideoPage from "../components/User/VideoPage/Video"

import AboutPage from "../components/CustomComponents/About"

import { Sidebar, Segment } from "semantic-ui-react"
import { isMobile } from "react-device-detect"

const NavPageWrapper = ({ children, navSizeBar, onClickHamburger }) => {
    const [isMobileDevice, setIsMobileDevice] = useState(false)

    useEffect(() => {
        if (isMobile) setIsMobileDevice(true)
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>โวหาร</title>
            </Helmet>
            {isMobileDevice && (
                <Sidebar.Pushable
                    as={Segment}
                    style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                    }}
                >
                    <NavigationSideBar
                        full={navSizeBar}
                        isMobileDevice={isMobileDevice}
                        onClickHamburger={onClickHamburger}
                    />
                    <Sidebar.Pusher
                        // dimmed={navSizeBar}
                        style={{
                            height: "100%",
                            overflowY: "scroll",
                        }}
                        data-simplebar
                    >
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            )}
            {!isMobileDevice && (
                <>
                    <NavigationBar
                        full={navSizeBar}
                        isMobileDevice={isMobileDevice}
                    />
                    {children}
                </>
            )}
        </>
    )
}

const Container = styled.div`
     {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        
    }
`

const App = () => {
    const [navSizeBar, setNavSideBar] = useState(true)
    const onClickHamburger = () => {
        setNavSideBar(!navSizeBar)
    }

    return (
        <Layout>
            <Container>
                <Header onClickHamburger={() => onClickHamburger()} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        height: isMobile ? "100vh" : "inherit",
                    }}
                >
                    <NavPageWrapper
                        navSizeBar={navSizeBar}
                        onClickHamburger={onClickHamburger}
                    >
                        <div style={{ display: "flex", flex: "1" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "hidden"
                                }}
                            >
                                <Breadcrumb />
                                <Router
                                    style={{
                                        backgroundColor: "#121212",
                                    }}
                                >
                                    <PublicRoute
                                        path="/"
                                        component={UserSelectionPage}
                                    />
                                    <PublicRoute
                                        path="/about"
                                        component={AboutPage}
                                    />
                                    <PublicRoute
                                        path="/video/:videoId"
                                        component={UserVideoPage}
                                    />

                                    <PrivateRoute
                                        path="/admin"
                                        component={AdminSelectionPage}
                                    />
                                    <PrivateRoute
                                        path="/admin/about"
                                        component={AboutPage}
                                    />
                                    <PrivateRoute
                                        path="/admin/video/:videoId"
                                        component={AdminVideoPage}
                                    />
                                </Router>
                            </div>
                        </div>
                    </NavPageWrapper>
                </div>
            </Container>
        </Layout>
    )
}

export default App
