import React from "react"
import { navigate } from "@reach/router"
import { useRecoilState } from "recoil"
import { PageState } from "../../../recoil/atoms"
import { HOME, ABOUT } from "../../../constants/routes"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"
import colors from "../../../styles/global.scss"
import { Menu, Sidebar } from "semantic-ui-react"
import Icon from "../Icon"

const _minWidth = 60 //'px'
const _maxWidth = 232 //'px'

const NavSideBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // margin-top: 60px;
`

const NavItemIcon = styled.div`
     {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        min-height: 48px;
    }
`

const NavItemText = styled.div`
     {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Prompt-Regular;
        font-size: 1rem;
        max-width: ${_maxWidth}px;
        color: ${colors.white};
    }
`

const NavItem = styled(Menu.Item)`
    &.item {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        overflow: hidden;
        margin: 0;
        background-color: ${colors.card};
    }
    &.active {
        background-color: ${colors.lightGrey};

        ${NavItemText} {
            font-family: Prompt-SemiBold;
        }
    }
`

const NavWrapper = styled.div`
     {
        display: flex;
        flex-direction: column;
        background-color: ${colors.card};
        // margin-top: 60px;
        height: 100%;
        width: 100%;
        min-width: ${props => (props.isFull ? "120px" : null)};
        border-radius: 0;
    }
`

const SidebarStyle = styled(Sidebar)`
     {
        height: 100% !important;
        margin-top: 60px !important;
    }
`
const SideBarNav = ({ children, full }) => (
    <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={full}
        width="thin"
    >
        {children}
    </Sidebar>
)

export const NavigationSideBar = props => {
    const [activePage, setActivePage] = useRecoilState(PageState)

    const onClickNavItem = item => {
        props.onClickHamburger()
        setActivePage(item)

        // setNavSelection(navSections.push({ key: 'Home', content: 'Test', link: true }))
        if (location.pathname.match(/^\/admin/)) {
            navigate(`/admin${item}`, { replace: true })
        } else {
            navigate(`${item}`, { replace: true })
        }
    }

    return (
        <SideBarNav full={props.full}>
            <NavSideBarWrapper>
                <NavItem
                    active={activePage === HOME}
                    onClick={() => onClickNavItem(HOME)}
                >
                    <NavItemIcon>
                        <Icon name="video" color={colors.white} />
                    </NavItemIcon>
                    <NavItemText>วิดีโอ</NavItemText>
                </NavItem>
                <NavItem
                    active={activePage === ABOUT}
                    onClick={() => onClickNavItem(ABOUT)}
                >
                    <NavItemIcon>
                        <Icon name="info" color={colors.white} />
                    </NavItemIcon>
                    <NavItemText>เกี่ยวกับ</NavItemText>
                </NavItem>
            </NavSideBarWrapper>
        </SideBarNav>
    )
}

const Nav = ({ children, full }) => (
    <motion.div
        initial={{ width: `${_maxWidth}px` }}
        animate={
            full ? { width: `${_maxWidth}px` } : { width: `${_minWidth}px` }
        }
        transition={{ duration: 0.25 }}
    >
        <NavWrapper inverted vertical isFull={full}>
            {children}
        </NavWrapper>
    </motion.div>
)

export const NavigationBar = props => {
    const [activePage, setActivePage] = useRecoilState(PageState)

    const onClickNavItem = item => {
        setActivePage(item)

        // setNavSelection(navSections.push({ key: 'Home', content: 'Test', link: true }))
        if (location.pathname.match(/^\/admin/)) {
            navigate(`/admin${item}`, { replace: true })
        } else {
            navigate(`${item}`, { replace: true })
        }
    }

    return (
        <Nav full={props.full}>
            <NavItem
                active={activePage === HOME}
                onClick={() => onClickNavItem(HOME)}
            >
                <NavItemIcon>
                    <Icon name="video" color={colors.white} />
                </NavItemIcon>
                <NavItemText>วิดีโอ</NavItemText>
            </NavItem>
            <NavItem
                active={activePage === ABOUT}
                onClick={() => onClickNavItem(ABOUT)}
            >
                <NavItemIcon>
                    <Icon name="info" color={colors.white} />
                </NavItemIcon>
                <NavItemText>เกี่ยวกับ</NavItemText>
            </NavItem>
        </Nav>
    )
}

NavigationBar.PropType = {
    color: PropTypes.string.isRequired,
}
NavigationSideBar.PropType = {
    color: PropTypes.string.isRequired,
}
// export default NavigationBar
