import React from 'react'
import styled from 'styled-components'
import SubtitleEditField from '../../SubtitleEditField/SubtitleEditField';
import ConfirmModal from '../ConfirmModal';
import Button from '../../Button'
import colors from '../../../../styles/global.scss'

const Modal = styled(ConfirmModal)`
    &.ui.modal {
        width: 408px;
        height: 377px;
        padding-left: 24px;
        padding-right: 24px;

        .content {
            pointer-events: none;
            margin-top: 30px;
            margin-bottom: 24px;

            .ui.card {
                margin: 0;
            }
        }

        .actions {
            .ui.button {
                width: 82px;
                height: 36px;
            }

            .ui.positive.button {
                background-color: ${colors.delete};
            }
        }
    }
`

function DeleteSubtitle(props) {
    const { ...rest } = props
    return (
        <Modal {...rest}>
            <Modal.Header>
                ต้องการลบช่องนี้
            </Modal.Header>
            <Modal.Content>
                <SubtitleEditField
                    index={props.index}
                    subtitle={props.subtitle}
                    length={props.length}
                    menuitems={props.menuitems}
                    
                />
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => { props.onClose() }}>
                    ยกเลิก
                </Button>
                <Button positive onClick={() => { props.handleDeleteSubtitle() }}>
                    ลบช่องนี้
                </Button>
            </Modal.Actions>
        </Modal >
    )
}

export default DeleteSubtitle